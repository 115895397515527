import React, { Component } from 'react';
import styles from '../scss/header.module.scss';
import cn from 'classnames';
import { TimelineLite, TweenMax, Power1, Power2, Power3 } from "gsap";
import { Link, useLocation } from "react-router-dom";
import profile from '../assets/images/profile.webp';

class Header extends Component {
    constructor(props) {
        super(props);
        this.myTween = null;
        this.state = {
            menuOpen: false
        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        TweenMax.set(document.getElementById("menuWrapper"), {y: 0, opacity: 0, zIndex: -1});
        let el3 = document.getElementById('projectLink');
        el3.classList.add( cn(styles.statusHide) );
    }
    handleScroll(event) {
        let el = document.getElementById('headerStatus');
        let el2 = document.getElementById('projectLink');
        if( window.scrollY > 150 ) {
            el.classList.add( cn(styles.statusHide) );
            el2.classList.remove( cn(styles.statusHide) );
        } else if( window.scrollY < 100 ) {
            el.classList.remove( cn(styles.statusHide) );
            el2.classList.add( cn(styles.statusHide) );
        }
    }
    closeMenu(e) {
        var tl = new TimelineLite();
        for (let i = 0; i < document.getElementById("mainMenuList").children.length; i++) {
            tl.to(document.getElementById("mainMenuList").children[i], 0.25, {y:-80, opacity:0, ease:Power1.easeIn }, i * 0.05)
        };
        if( e.target.href == window.location.href ) {
            TweenMax.to(document.getElementById("content"), 0.4, {force3D:true, opacity:1, y: 0, delay:0.45, ease:Power3.easeOut});
        }
        TweenMax.to(document.getElementById("menuWrapper"), 0.4, {y: 0, opacity: 0, zIndex: -1}).delay(.5);
        this.setState({ menuOpen: !this.state.menuOpen });
    }
    toggleMenu() {
        this.setState({ menuOpen: !this.state.menuOpen });
        if( !this.state.menuOpen ) {
            TweenMax.to(document.getElementById("projectLink"), .2, {force3D: true, opacity: 0, y: -30, ease: Power2.easeInOut  });
            TweenMax.to(document.getElementById("headerStatus"), .6, {force3D: true, opacity: 0, y: -100, ease: Power2.easeInOut  });
            TweenMax.to(document.getElementById("menuWrapper"), 0.2, {y: 0, opacity: 1, zIndex: 99,  delay:.25});
            TweenMax.to(document.getElementById("content"), 0.4, {force3D: true, opacity: 0, y: -150, delay: 0.2, ease: Power2.easeInOut  });
            var tl = new TimelineLite();
            tl.set( document.getElementById("mainMenuList").children, {y: 60, opacity:0});
            setTimeout(function() {
                for (let i = 0; i < document.getElementById("mainMenuList").children.length; i++) {
                    tl.to(document.getElementById("mainMenuList").children[i], 0.5, {y:0, opacity:1, delay:0.3, ease:Power3.easeOut}, i * 0.075)
                }
            }, 300)
        } else {
            TweenMax.to(document.getElementById("projectLink"), 0.2, {force3D:true, opacity:1, y: 0, delay:0.4, ease:Power3.easeOut});
            TweenMax.to(document.getElementById("content"), 0.4, {force3D:true, opacity:1, y: 0, delay:0.45, ease:Power3.easeOut});
            setTimeout(function() {
                TweenMax.to(document.getElementById("headerStatus"), 0.4, {force3D:true, opacity:1, y: 0, delay:0.4, ease:Power3.easeOut});
                TweenMax.to(document.getElementById("menuWrapper"), 0.4, {y: 0, opacity: 0, zIndex: -1});
            }, 500)
            var tl = new TimelineLite();
            for (let i = 0; i < document.getElementById("mainMenuList").children.length; i++) {
                tl.to(document.getElementById("mainMenuList").children[i], 0.25, {y:-80, opacity:0, ease:Power3.easeIn}, i * 0.05)
            }
        }
    }
    render() {
        return (
            <>
                <script type="application/ld+json">{`
                    {
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "name": "Erik Ivanov",
                    "url": "https://erik-ivanov.de/",
                    "logo": "https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg",
                    "contactPoint": {
                        "@type": "ContactPoint",
                        "telephone": "038548597862",
                        "contactType": "customer service",
                        "contactOption": "TollFree",
                        "areaServed": "DE",
                        "availableLanguage": ["en","German","Russian"]
                    },
                    "sameAs": "https://instagram.com/erik.freelancer"
                    }
                    `}</script>
                <Link to="/" className={cn(styles.headerLogo)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="41.516" height="18.354" viewBox="0 0 41.516 18.354">
                        <g id="erik-logo" transform="translate(-0.5 -1)">
                            <path className={cn(styles.st0)} id="Pfad_1" d="M10.333,6.159h-5.9V9.437H9.677V12.5H4.433v3.5h5.9V19.16H.5V3.1h9.833Z" transform="translate(0 0.194)" fill="#1f1f1f"/>
                            <path className={cn(styles.st0)} id="Pfad_2" d="M15.733,18.892H11.8V6.109h3.933V8.185A4.778,4.778,0,0,1,19.775,6v4.152H18.683c-1.857,0-2.95.656-2.95,2.841Z" transform="translate(1.046 0.463)" fill="#1f1f1f"/>
                            <path className={cn(styles.st0)} id="Pfad_3" d="M20.4,3.185A2.217,2.217,0,0,1,22.694,1a2.136,2.136,0,1,1,0,4.261A2.2,2.2,0,0,1,20.4,3.185Zm.437,3.387H24.77V19.354H20.837Z" transform="translate(1.841)" fill="#1f1f1f"/>
                            <path className={cn(styles.st0)} id="Pfad_4" d="M26.9,2.3h3.933v9.4l3.824-5.244h4.807L34.22,12.9l5.353,6.337H34.766l-3.933-5.353v5.353H27.009L26.9,2.3Z" transform="translate(2.443 0.12)" fill="#1f1f1f"/>
                        </g>
                    </svg>
                </Link>
                <div id="headerStatus" className={cn(styles.headerStatus)}>
                    <div className={cn(styles.headerOnline)}>
                        <div className={cn(styles.headerBlob)}></div>
                        <span>Verfügbar für neue Aufträge</span>
                    </div>
                </div>
                <Link id="projectLink" to="/konfigurator/" className={cn(styles.headerButton) + ' btn gold'} title="Projekt starten">Projekt starten</Link>
                <button className={ this.state.menuOpen ? cn(styles.burger) + ' ' + cn(styles.active) + ' hoverable' : cn(styles.burger) + ' hoverable'} onClick={e => this.toggleMenu()}></button>
                <div id="menuWrapper" className={cn(styles.menuWrapper)}>
                    <div className={cn(styles.menuInner)}>
                        <div className={cn(styles.menuProfile)}>
                            <img src={profile} alt="Profile Picture"/>
                            <p>
                                <span>Kontaktmöglichkeiten</span><br/>
                                <a href="mailto:hallo@erik-ivanov.de" title="Nachricht schreiben">hallo@erik-ivanov.de</a><br/>
                                <a href="tel:+4938548597862" title="Jetzt anrufen">0385 48597862</a>
                            </p>
                            <p>
                                <span>ÖFFNUNGSZEITEN</span><br/>
                                MONTAG-FREITAG<br/>
                                08.00 - 16.00 Uhr
                            </p>
                        </div>
                        <nav id="mainMenu" className={cn(styles.mainMenu)}>
                            <ul id="mainMenuList">
                                <li>
                                    <Link to="/" title="Start" onClick={(e) => this.closeMenu(e)}>
                                        Start
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/referenzen/" title="Referenzen" onClick={(e) => this.closeMenu(e)}>
                                        Referenzen
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/leistungen/" title="Leistungen" onClick={(e) => this.closeMenu(e)}>
                                        Leistungen
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/ueber-mich/" title="Über Mich" onClick={(e) => this.closeMenu(e)}>
                                        Über Mich
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/blog/" title="Blog" onClick={(e) => this.closeMenu(e)}>
                                        Blog
                                        <span>Neu</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/kontakt/" title="Kontakt" onClick={(e) => this.closeMenu(e)}>
                                        Kontakt
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <svg className={cn(styles.menuLines)} xmlns="http://www.w3.org/2000/svg" width="1479.094" height="1080.024" viewBox="0 0 1479.094 1080.024">
                        <path id="intro-line" d="M1516.595,0s-28.829,752.727-434.086,741.818S616.212,509.61,338.121,600,38,1080,38,1080" transform="translate(-37.75 0.01)" fill="none" stroke="#cdcdcd" strokeMiterlimit="10" strokeWidth="0.5" opacity="0.8"/>
                    </svg>
                </div>
            </>
        );
    }
}
 
export default Header;