import React, { Component } from 'react';
import styles from '../scss/intro.module.scss';
import cn from 'classnames';

import profilePicture from '../assets/images/profile.webp';

const mothNames = {
    1: 'Januar',
    2: 'Februar',
    3: 'März',
    4: 'April',
    5: 'Mai',
    6: 'Juni',
    7: 'Juli',
    8: 'August',
    9: 'September',
    10: 'Oktober',
    11: 'November',
    12: 'Dezember'
}

class BlogIntro extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        title: this.props.title,
        date: this.props.date,
        slug: this.props.slug,
        image: this.props.image
    }
    componentDidMount() {
        this.setState({ title: this.props.title, date: this.props.date, slug: this.props.slug });
    }
    render() {
        return (
            <section className={cn(styles.blogWrapper)}>
            <div className={cn(styles.introInner)}>
                <picture>
                    <source media="(min-width:767px)" srcset={this.state.image.blogIntro.source_url + '.webp'}/>
                    <source media="(min-width:480px)" srcset={this.state.image.blogIntroMd.source_url + '.webp'}/>
                    <img className={cn(styles.blogBackground)} src={this.state.image.blogIntroSm.source_url + '.webp'} alt="" />
                </picture>
                <h1>{this.state.title}</h1>
                <div className={cn(styles.blogMeta)}>
                    <div className={cn(styles.blogAuthor)}>
                        <div className={cn(styles.blogAuthorImage)}>
                            <img src={profilePicture} alt="" />
                        </div>
                        <div className={cn(styles.blogAuthorMeta)}>
                            <h6>Erik Ivanov</h6>
                            <span>{new Date(this.state.date).getDate()}. {mothNames[new Date(this.state.date).getMonth() + 1]} {new Date(this.state.date).getFullYear()}</span>
                        </div>
                    </div>
                    <div className={cn(styles.blogSocialShare)}>
                        <div className={cn(styles.blogSocialItem)}>
                            <a href={`https://www.facebook.com/sharer/sharer.php?u=https://erik-ivanov.de/blog/${this.state.slug}`} target="_blank" rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                    <g id="Gruppe_107" data-name="Gruppe 107" transform="translate(-1178 -622)">
                                        <circle id="Ellipse_15" data-name="Ellipse 15" cx="13" cy="13" r="13" transform="translate(1178 622)" fill="#fff"/>
                                        <path id="logo-facebook_8_" data-name="logo-facebook (8)" d="M46.575,40.637a7.287,7.287,0,1,0-8.426,7.2V42.744H36.3V40.637h1.851V39.032A2.572,2.572,0,0,1,40.9,36.2a11.2,11.2,0,0,1,1.632.142v1.794h-.919a1.054,1.054,0,0,0-1.188,1.139v1.366h2.021l-.323,2.107h-1.7v5.093A7.289,7.289,0,0,0,46.575,40.637Z" transform="translate(1151.713 594.406)" fill="#1f1f1f" fill-rule="evenodd"/>
                                    </g>
                                </svg>
                            </a>
                        </div>
                        <div className={cn(styles.blogSocialItem)}>
                            <a href={`https://twitter.com/intent/tweet?url=https://erik-ivanov.de/blog/${this.state.slug}&text=${this.state.title}`} target="_blank" rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                    <g id="Gruppe_108" data-name="Gruppe 108" transform="translate(-1210 -622)">
                                        <circle id="Ellipse_14" data-name="Ellipse 14" cx="13" cy="13" r="13" transform="translate(1210 622)" fill="#fff"/>
                                        <path id="logo-twitter_1_" data-name="logo-twitter (1)" d="M30.215,65.347a5.976,5.976,0,0,1-1.675.453,2.888,2.888,0,0,0,1.283-1.587,5.856,5.856,0,0,1-1.853.7A2.936,2.936,0,0,0,25.841,64a2.893,2.893,0,0,0-2.916,2.87,2.76,2.76,0,0,0,.075.654,8.313,8.313,0,0,1-6.012-3,2.834,2.834,0,0,0-.4,1.442,2.862,2.862,0,0,0,1.3,2.39A2.887,2.887,0,0,1,16.569,68v.036a2.883,2.883,0,0,0,2.34,2.813,2.984,2.984,0,0,1-.768.1,2.8,2.8,0,0,1-.548-.053,2.915,2.915,0,0,0,2.726,1.993A5.911,5.911,0,0,1,16.7,74.116a6.014,6.014,0,0,1-.7-.041A8.312,8.312,0,0,0,28.769,67.2c0-.124,0-.249-.009-.37A5.878,5.878,0,0,0,30.215,65.347Z" transform="translate(1199.893 565.314)" fill="#1f1f1f"/>
                                    </g>
                                </svg>
                            </a>
                        </div>
                        <div className={cn(styles.blogSocialItem)}>
                            <a href={`https://www.linkedin.com/sharing/share-offsite/?url=https://erik-ivanov.de/blog/${this.state.slug}`} target="_blank" rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                    <g id="Gruppe_109" data-name="Gruppe 109" transform="translate(-1242 -622)">
                                        <circle id="Ellipse_13" data-name="Ellipse 13" cx="13" cy="13" r="13" transform="translate(1242 622)" fill="#fff"/>
                                        <path id="logo-linkedin" d="M43.916,32H33.107A1.056,1.056,0,0,0,32,33.009V43.842a1.14,1.14,0,0,0,1.107,1.11H43.912a1.082,1.082,0,0,0,1.039-1.11V33.009A.993.993,0,0,0,43.916,32Zm-7.9,10.8H34.159V37.027h1.855Zm-.864-6.646h-.013a1.009,1.009,0,1,1,.013,0ZM42.8,42.8H40.94V39.641c0-.756-.27-1.272-.941-1.272a1.014,1.014,0,0,0-.951.685,1.25,1.25,0,0,0-.064.456V42.8H37.128V37.027h1.855v.8a1.879,1.879,0,0,1,1.673-.938c1.218,0,2.139.8,2.139,2.534Z" transform="translate(1216.524 596.524)" fill="#1f1f1f"/>
                                    </g>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        );
    }
}
 
export default BlogIntro;