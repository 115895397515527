import React, { Component } from 'react';
import { connect } from "react-redux"
import { updateConfig } from '../redux/actions/configurator.actions'
import { Link } from "react-router-dom";
import cn from 'classnames';
import styles from '../scss/configurator.module.scss';

class Konfigurator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            configurator: this.props.configurator
        }
    }
    calcuateWebsite( type ) {
        let price = this.props.configurator.basePrice;
        let brancheSurcharge = this.props.configurator.brancheSurcharge;
        let sitesSurcharge = this.props.configurator.sitesSurcharge;
        let googleSurcharge = this.props.configurator.googleSurcharge;
        price = brancheSurcharge + price + googleSurcharge;
        if( type == 'support' ) {
            return String( new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format( ( brancheSurcharge + sitesSurcharge + price ) / 2 ) ) + '\xa0-\xa0' + String( new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format( ( brancheSurcharge + price + 850 ) / 2 ) );
        } else {
            return String(brancheSurcharge + sitesSurcharge + price) + '\xa0-\xa0' + String(brancheSurcharge + sitesSurcharge + price + 850) + '\xa0€';
        }
    }
    updateText(text) {
        let h1 = document.getElementById("calculation");
        h1.innerHTML = text
            .split("")
            .map(letter => {
            return `<span>` + letter + `</span>`;
            })
            .join("");

        Array.from(h1.children).forEach((span, index) => {
            setTimeout(() => {
            span.classList.add("wavy");
            }, index * 60);
        });
    }
    componentWillReceiveProps( nextProps ) {
        this.setState({ configurator: nextProps.configurator });
    }
    componentDidUpdate() {
        this.updateText( this.calcuateWebsite() );
    }
    componentDidMount() {
        this.updateText( this.calcuateWebsite() );
    }
    render() { 
        return (
            <>
                <section className={cn(styles.confContainer)}>
                    <div className="container">
                        <div className={cn(styles.confHeadline)}>
                            <h1>Jetzt individuelle Webseite <span>konfigurieren.</span></h1>
                        </div>
                        <div className={cn(styles.confWrapper)}>
                            <div className={cn(styles.confInner)}>
                                <section className={cn(styles.confSettings)}>
                                    <div className={cn(styles.confStep)}>
                                        <div className={cn(styles.confBlock)}>
                                            <div className={cn(styles.confQuestion)}>
                                                <h3>Wie heißt Ihr Unternehmen?</h3>
                                            </div>
                                            <div className={cn(styles.confUserInput)}>
                                                <input type="text" name="companyName" placeholder="Mustermann GmbH" value={this.state.companyName} onChange={(e) => this.props.updateConfig({ companyName: e.target.value })}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cn(styles.confStep)}>
                                        <div className={cn(styles.confBlock)}>
                                            <div className={cn(styles.confQuestion)}>
                                                <h3>Haben Sie bereits eine Webseite?</h3>
                                                <p>Gibt es bereits für Ihr Unternehmen eine Webseite? Geben Sie uns gerne den Link zu Ihrer Webseite.</p>
                                            </div>
                                            <div className={cn(styles.confUserInput)}>
                                                <div className={cn(styles.confUserInputItem)}>
                                                    <a className={(this.state.configurator.hasWebsite) ? 'active btn outline' : 'btn outline'} title="Ja" onClick={(e) => this.props.updateConfig({ hasWebsite: true }) }>Ja</a>
                                                </div>
                                                <div className={cn(styles.confUserInputItem)}>
                                                    <a className={(this.state.configurator.hasWebsite) ? 'btn outline' : 'active btn outline'} title="Nein" onClick={(e) => this.props.updateConfig({ hasWebsite: false }) }>Nein</a>
                                                </div>
                                            </div>
                                            {(this.state.configurator.hasWebsite) ?
                                                <div className={cn(styles.confAdditional)}>
                                                    <input type="url" name="websiteURL" placeholder="https://ihrewebsite.de/" value={this.state.websiteURL} onChange={(e) => this.props.updateConfig({ websiteURL: e.target.value })}/>
                                                </div>
                                            :
                                                null
                                            }
                                        </div>
                                    </div>
                                    <div className={cn(styles.confStep)}>
                                        <div className={cn(styles.confBlock)}>
                                            <div className={cn(styles.confQuestion)}>
                                                <h3>Haben Sie bereits ein Logo?</h3>
                                                <p>Wurde in der Vergangenheit bereits ein Logo für Ihr Unternehmen erstellt? Wünschenswert wären die Formate <code>.svg</code>, <code>.eps</code> und <code>.ai</code></p>
                                            </div>
                                            <div className={cn(styles.confUserInput)}>
                                                <div className={cn(styles.confUserInputItem)}>
                                                    <a className={(this.state.configurator.hasLogo) ? 'active btn outline' : 'btn outline'} title="Ja" onClick={(e) => this.props.updateConfig({ hasLogo: true })}>Ja</a>
                                                </div>
                                                <div className={cn(styles.confUserInputItem)}>
                                                    <a className={(this.state.configurator.hasLogo) ? 'btn outline' : 'active btn outline'} title="Nein" onClick={(e) => this.props.updateConfig({ hasLogo: false })}>Nein</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cn(styles.confStep)}>
                                        <div className={cn(styles.confBlock)}>
                                            <div className={cn(styles.confQuestion)}>
                                                <h3>In welcher Branche sind Sie tätig?</h3>
                                                <p>Anhand Ihrer Branche bewerten wir Ihre ungefähren Bedürfnisse für Ihre zukünftige Homepage.</p>
                                            </div>
                                            <div className={cn(styles.confUserInput)}>
                                                <div className={cn(styles.confUserInputItem)}>
                                                    <a className={(this.state.configurator.branch.advisory) ? 'active btn outline' : 'btn outline'} title="Beratung" onClick={(e) => this.props.updateConfig({ brancheSurcharge: ( 0 ), branch: { advisory: true, service: false, gastronomy: false, health: false, trade: false, craft: false, industry: false, other: false }, selectedBranch: 'Beratung' })}>Beratung</a>
                                                </div>
                                                <div className={cn(styles.confUserInputItem)}>
                                                    <a className={(this.state.configurator.branch.service) ? 'active btn outline' : 'btn outline'} title="Dienstleistung" onClick={(e) => this.props.updateConfig({ brancheSurcharge: ( 250 ), branch: { advisory: false, service: true, gastronomy: false, health: false, trade: false, craft: false, industry: false, other: false }, selectedBranch: 'Dienstleistung' })}>Dienstleistung</a>
                                                </div>
                                                <div className={cn(styles.confUserInputItem)}>
                                                    <a className={(this.state.configurator.branch.gastronomy) ? 'active btn outline' : 'btn outline'} title="Gastronomie" onClick={(e) => this.props.updateConfig({ brancheSurcharge: ( 300 ), branch: { advisory: false, service: false, gastronomy: true, health: false, trade: false, craft: false, industry: false, other: false }, selectedBranch: 'Gastronomie' })}>Gastronomie</a>
                                                </div>
                                                <div className={cn(styles.confUserInputItem)}>
                                                    <a className={(this.state.configurator.branch.health) ? 'active btn outline' : 'btn outline'} title="Gesundheit" onClick={(e) => this.props.updateConfig({ brancheSurcharge: ( 300 ), branch: { advisory: false, service: false, gastronomy: false, health: true, trade: false, craft: false, industry: false, other: false }, selectedBranch: 'Gesundheit' })}>Gesundheit</a>
                                                </div>
                                                <div className={cn(styles.confUserInputItem)}>
                                                    <a className={(this.state.configurator.branch.trade) ? 'active btn outline' : 'btn outline'} title="Handel" onClick={(e) => this.props.updateConfig({ brancheSurcharge: ( 1000 ), branch: { advisory: false, service: false, gastronomy: false, health: false, trade: true, craft: false, industry: false, other: false }, selectedBranch: 'Handel' })}>Handel</a>
                                                </div>
                                                <div className={cn(styles.confUserInputItem)}>
                                                    <a className={(this.state.configurator.branch.craft) ? 'active btn outline' : 'btn outline'} title="Handwerk" onClick={(e) => this.props.updateConfig({ brancheSurcharge: ( 300 ), branch: { advisory: false, service: false, gastronomy: false, health: false, trade: false, craft: true, industry: false, other: false }, selectedBranch: 'Handwerk' })}>Handwerk</a>
                                                </div>
                                                <div className={cn(styles.confUserInputItem)}>
                                                    <a className={(this.state.configurator.branch.industry) ? 'active btn outline' : 'btn outline'} title="Industrie" onClick={(e) => this.props.updateConfig({ brancheSurcharge: ( 750 ), branch: { advisory: false, service: false, gastronomy: false, health: false, trade: false, craft: false, industry: true, other: false }, selectedBranch: 'Industrie' })}>Industrie</a>
                                                </div>
                                                <div className={cn(styles.confUserInputItem)}>
                                                    <a className={(this.state.configurator.branch.other) ? 'active btn outline' : 'btn outline'} title="Sonstige" onClick={(e) => this.props.updateConfig({ brancheSurcharge: ( 750 ), branch: { advisory: false, service: false, gastronomy: false, health: false, trade: false, craft: false, industry: false, other: true }, selectedBranch: 'Sonstige' })}>Sonstige</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cn(styles.confStep)}>
                                        <div className={cn(styles.confBlock)}>
                                            <div className={cn(styles.confQuestion)}>
                                                <h3>Wie viele Unterseiten benötigen Sie etwa?</h3>
                                                <p>Seiten innerhalb Ihrer Homepage, werden als Unterseiten bezeichnet. Wie viele Unterseiten planen Sie aktuell ein?</p>
                                            </div>
                                            <div className={cn(styles.confUserInput)}>
                                                <div className={cn(styles.confUserInputItem)}>
                                                    <a className={(this.state.configurator.sites.onethree) ? 'active btn outline' : 'btn outline'} title="1-3 Seiten" onClick={(e) => this.props.updateConfig({ sitesSurcharge: ( 0 ), sites: { onethree: true, foursix: false, sevennine: false, morenine: false } })}>1-3 Seiten</a>
                                                </div>
                                                <div className={cn(styles.confUserInputItem)}>
                                                    <a className={(this.state.configurator.sites.foursix) ? 'active btn outline' : 'btn outline'} title="4-6 Seiten" onClick={(e) => this.props.updateConfig({ sitesSurcharge: ( 550 ), sites: { onethree: false, foursix: true, sevennine: false, morenine: false } })}>4-6 Seiten</a>
                                                </div>
                                                <div className={cn(styles.confUserInputItem)}>
                                                    <a className={(this.state.configurator.sites.sevennine) ? 'active btn outline' : 'btn outline'} title="7-9 Seiten" onClick={(e) => this.props.updateConfig({ sitesSurcharge: ( 900 ), sites: { onethree: false, foursix: false, sevennine: true, morenine: false } })}>7-9 Seiten</a>
                                                </div>
                                                <div className={cn(styles.confUserInputItem)}>
                                                    <a className={(this.state.configurator.sites.morenine) ? 'active btn outline' : 'btn outline'} title="mehr als 9 Seiten" onClick={(e) => this.props.updateConfig({ sitesSurcharge: ( 1450 ), sites: { onethree: false, foursix: false, sevennine: false, morenine: true } })}>mehr als 9 Seiten</a>
                                                </div>
                                            </div>
                                            {(!this.state.configurator.sites.onethree) ?
                                                <div className={cn(styles.confUserAddup)}>+ {this.state.configurator.sitesSurcharge}€</div>
                                            :
                                                null
                                            }
                                        </div>
                                    </div>
                                    <div className={cn(styles.confStep)}>
                                        <div className={cn(styles.confBlock)}>
                                            <div className={cn(styles.confQuestion)}>
                                                <h3>Möchten Sie ein Google My Business Profil?</h3>
                                                <p>Lassen Sie Ihre Kunden nicht lange suchen! Mit einem Google My Business Profil tragen wir Ihr Unternehmen im Google Verzeichnis sowie bei Google Maps ein.</p>
                                            </div>
                                            <div className={cn(styles.confUserInput)}>
                                                <div className={cn(styles.confUserInputItem)}>
                                                    <a className={(this.state.configurator.googleBusiness) ? 'active btn outline' : 'btn outline'} title="Ja" onClick={(e) => this.props.updateConfig({ googleBusiness: true, googleSurcharge: 75 })}>Ja</a>
                                                </div>
                                                <div className={cn(styles.confUserInputItem)}>
                                                    <a className={(this.state.configurator.googleBusiness) ? 'btn outline' : 'active btn outline'} title="Nein" onClick={(e) => this.props.updateConfig({ googleBusiness: false, googleSurcharge: 0 })}>Nein</a>
                                                </div>
                                            </div>
                                            {(this.state.configurator.googleBusiness) ?
                                                <div className={cn(styles.confUserAddup)}>+ 55€</div>
                                            :
                                                null
                                            }
                                        </div>
                                    </div>
                                </section>
                                <section className={cn(styles.confCalculation)}>
                                    <div className={cn(styles.confCalculationBlock)}>
                                        <div className={cn(styles.confCalculationInner)}>
                                            <h3>Ihre Konfiguration</h3>
                                            <div className={cn(styles.confPriceWrapper)}>
                                                <div className={cn(styles.confPriceText)}>
                                                    <div id="calculation" className="wavy"></div>
                                                    <span>geschätzte Preisspanne</span>
                                                </div>
                                                <div className={cn(styles.confPriceTax)}>
                                                    zzgl. MwSt.
                                                </div>
                                            </div>
                                        </div>
                                        <Link to="/konfigurator/auswertung/" className="btn gold block" title="Jetzt Angebot anfordern">
                                            Jetzt Angebot anfordern
                                        </Link>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

const mapStateToProps = (state)=>{
    return{
        configurator: state.configurator.configurator
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        updateConfig: (state)=>{dispatch(updateConfig(state))}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Konfigurator)