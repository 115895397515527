import React, { Component } from 'react';
import handleViewport from 'react-in-viewport';
import { Link } from 'react-router-dom';
import styles from '../scss/projects.module.scss';
import cn from 'classnames';
import Tilt from 'react-parallax-tilt';

import airbeat from '../assets/images/airbeat-one.webp';
import beglau from '../assets/images/beglau-waermepumpen.webp';
import julia from '../assets/images/julia-cosmetics.webp';
import buck from '../assets/images/sven-buck.webp';
import wilts from '../assets/images/wilts-keyser.webp';
import trendline from '../assets/images/trendline-traumhaus.webp';

class ProjectsListBlock extends Component {
    getStyle() {
    const { inViewport, enterCount } = this.props;
        if (inViewport && enterCount === 1) {
            return { WebkitTransition: '0.5s ease-in-out' };
        } else if (!inViewport && enterCount < 1) {
            return { WebkitTransition: 'none', opacity: '0' };
        } else {
            return {};
        }
    }
    render() { 
        const { enterCount, leaveCount } = this.props;
        return (
            <section className={cn(styles.projects)} style={this.getStyle()}>
                <div className="container">
                    <div className="headline">
                        <h2>Eine Auswahl<br/><span>unserer Arbeiten.</span></h2>
                    </div>
                    <div className={cn(styles.projectsWrapper)}>
                        <Tilt className={cn(styles.projectsItem)} tiltMaxAngleX={1} tiltMaxAngleY={1}>
                            <Link to="/referenzen/airbeat-one/" title="Airbeat One">
                                <figure className={cn(styles.projectsFigure)}>
                                    <img src={airbeat} alt="Airbeat One"/>
                                    <figcaption>
                                        <div className={cn(styles.projectsCaption)}>
                                            <p className={cn(styles.projectsSubTitle)}>Neustadt-Glewe</p>
                                            <h4>Airbeat One</h4>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.643" height="20.037" viewBox="0 0 20.643 20.037">
                                            <path id="arrow-forward" d="M110.038,112l8.6,8.6-8.6,8.6m7.409-8.6H100" transform="translate(-99 -110.586)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        </svg>
                                    </figcaption>
                                </figure>
                            </Link>
                        </Tilt>
                        <Tilt className={cn(styles.projectsItem)} tiltMaxAngleX={1} tiltMaxAngleY={1}>
                            <Link to="/referenzen/beglau-waermepumpen/" title="Beglau Wärmepumpen">
                                <figure className={cn(styles.projectsFigure)}>
                                    <img src={beglau} alt="Beglau Wärmepumpen"/>
                                    <figcaption>
                                        <div className={cn(styles.projectsCaption)}>
                                            <p className={cn(styles.projectsSubTitle)}>RAMPE</p>
                                            <h4>Beglau Wärmepumpen</h4>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.643" height="20.037" viewBox="0 0 20.643 20.037">
                                            <path id="arrow-forward" d="M110.038,112l8.6,8.6-8.6,8.6m7.409-8.6H100" transform="translate(-99 -110.586)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        </svg>
                                    </figcaption>
                                </figure>
                            </Link>
                        </Tilt>
                        <Tilt className={cn(styles.projectsItem)} tiltMaxAngleX={1} tiltMaxAngleY={1}>
                            <Link to="/referenzen/julia-cosmetics/" title="Beglau Wärmepumpen">
                                <figure className={cn(styles.projectsFigure)}>
                                    <img src={julia} alt="Beglau Wärmepumpen"/>
                                    <figcaption>
                                        <div className={cn(styles.projectsCaption)}>
                                            <p className={cn(styles.projectsSubTitle)}>KIEL</p>
                                            <h4>Julia Cosmetics</h4>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.643" height="20.037" viewBox="0 0 20.643 20.037">
                                            <path id="arrow-forward" d="M110.038,112l8.6,8.6-8.6,8.6m7.409-8.6H100" transform="translate(-99 -110.586)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        </svg>
                                    </figcaption>
                                </figure>
                            </Link>
                        </Tilt>
                        <Tilt className={cn(styles.projectsItem)} tiltMaxAngleX={1} tiltMaxAngleY={1}>
                            <Link to="/referenzen/sven-buck/" title="Sven Buck & Partner">
                                <figure className={cn(styles.projectsFigure)}>
                                    <img src={buck} alt="Sven Buck & Partner"/>
                                    <figcaption>
                                        <div className={cn(styles.projectsCaption)}>
                                            <p className={cn(styles.projectsSubTitle)}>NEUSTADT-GLEWE</p>
                                            <h4>Sven Buck</h4>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.643" height="20.037" viewBox="0 0 20.643 20.037">
                                            <path id="arrow-forward" d="M110.038,112l8.6,8.6-8.6,8.6m7.409-8.6H100" transform="translate(-99 -110.586)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        </svg>
                                    </figcaption>
                                </figure>
                            </Link>
                        </Tilt>
                        <Tilt className={cn(styles.projectsItem)} tiltMaxAngleX={1} tiltMaxAngleY={1}>
                            <Link to="/referenzen/wilts-keyser/" title="Wilts & Keyser Rechtsanwälte">
                                <figure className={cn(styles.projectsFigure)}>
                                    <img src={wilts} alt="Wilts & Keyser Rechtsanwälte"/>
                                    <figcaption>
                                        <div className={cn(styles.projectsCaption)}>
                                            <p className={cn(styles.projectsSubTitle)}>SCHWERIN</p>
                                            <h4>Wilts & Keyser Rechtsanwälte</h4>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.643" height="20.037" viewBox="0 0 20.643 20.037">
                                            <path id="arrow-forward" d="M110.038,112l8.6,8.6-8.6,8.6m7.409-8.6H100" transform="translate(-99 -110.586)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        </svg>
                                    </figcaption>
                                </figure>
                            </Link>
                        </Tilt>
                        <Tilt className={cn(styles.projectsItem)} tiltMaxAngleX={1} tiltMaxAngleY={1}>
                            <Link to="/referenzen/trendline-traumhaus/" title="Trendline Traumhaus">
                                <figure className={cn(styles.projectsFigure)}>
                                    <img src={trendline} alt="Trendline Traumhaus"/>
                                    <figcaption>
                                        <div className={cn(styles.projectsCaption)}>
                                            <p className={cn(styles.projectsSubTitle)}>SCHWERIN</p>
                                            <h4>Trendline Traumhaus</h4>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.643" height="20.037" viewBox="0 0 20.643 20.037">
                                            <path id="arrow-forward" d="M110.038,112l8.6,8.6-8.6,8.6m7.409-8.6H100" transform="translate(-99 -110.586)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        </svg>
                                    </figcaption>
                                </figure>
                            </Link>
                        </Tilt>
                    </div>
                </div>
            </section>
        );
    }
}

const ProjectsList = handleViewport(ProjectsListBlock, { rootMargin: '-1.0px' });
 
export default ProjectsList;