import React, { Component } from 'react';
import handleViewport from 'react-in-viewport';
import { Link } from 'react-router-dom';
import styles from '../scss/projects.module.scss';
import cn from 'classnames';
import Tilt from 'react-parallax-tilt';

import blogWhatWebdesign from '../assets/images/blog-was-macht-eine-erfolgreiche-webseite-aus.jpg';

const mothNames = {
    1: 'Januar',
    2: 'Februar',
    3: 'März',
    4: 'April',
    5: 'Mai',
    6: 'Juni',
    7: 'Juli',
    8: 'August',
    9: 'September',
    10: 'Oktober',
    11: 'November',
    12: 'Dezember'
}

class BlogBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: []
        }
    }
    getStyle() {
        const { inViewport, enterCount } = this.props;
        if (inViewport && enterCount === 1) {
            return { WebkitTransition: '0.5s ease-in-out' };
        } else if (!inViewport && enterCount < 1) {
            return { WebkitTransition: 'none', opacity: '0' };
        } else {
            return {};
        }
    }
    async getPosts() {
        try {
            const response = await fetch(`https://cms.erik-ivanov.de/wp-json/wp/v2/posts/?_embed`);
            const json = await response.json();
            this.setState({ posts: json });
        } catch(error) {
            console.log(error);
        }
    }
    componentDidMount() {
        this.getPosts();
    }
    render() { 
        const { enterCount, leaveCount } = this.props;
        return (
            <section className={cn(styles.projects)} style={this.getStyle()}>
                <div className="container">
                    <div className={cn(styles.projectsWrapper)}>
                        {this.state.posts && this.state.posts.map((post, index) => {
                            let featuredImage = post._embedded['wp:featuredmedia'][0].media_details.sizes.blogThumbSm.source_url + '.webp';
                            let featuredImageMd = post._embedded['wp:featuredmedia'][0].media_details.sizes.blogThumbMd.source_url + '.webp';
                            let featuredImageFull = post._embedded['wp:featuredmedia'][0].media_details.sizes.blogThumb.source_url + '.webp';
                            let publishedDate = new Date(post.date);

                            return (
                                <Tilt key={index} className={cn(styles.projectsItem)} tiltMaxAngleX={1} tiltMaxAngleY={1}>
                                    <Link to={"/blog/" + post.slug + "/" } title={post.title.rendered}>
                                        <figure className={cn(styles.projectsFigure)}>
                                            <picture>
                                                <source media="(min-width:767px)" srcset={featuredImageFull}/>
                                                <source media="(min-width:480px)" srcset={featuredImageMd}/>
                                                <img src={featuredImage} alt={post._embedded['wp:featuredmedia'].media_details + ' Beitragsbild'}/>
                                            </picture>
                                            <figcaption className={cn(styles.blogCaption)}>
                                                <div className={cn(styles.projectsCaption)}>
                                                    <p className={cn(styles.projectsSubTitle)}>Webdesign • Erik Ivanov • {new Date(publishedDate).getDate()}. {mothNames[new Date(publishedDate).getMonth() + 1]} {new Date(publishedDate).getFullYear()}</p>
                                                    <h4>{post.title.rendered}</h4>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20.643" height="20.037" viewBox="0 0 20.643 20.037">
                                                    <path id="arrow-forward" d="M110.038,112l8.6,8.6-8.6,8.6m7.409-8.6H100" transform="translate(-99 -110.586)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                </svg>
                                            </figcaption>
                                        </figure>
                                    </Link>
                                </Tilt>
                            )
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

const Blog = handleViewport(BlogBlock, { rootMargin: '-1.0px' });
 
export default Blog;