import React, { Component } from 'react';
import cn from 'classnames';
import styles from '../scss/steps.module.scss';

class Steps extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        problem: this.props.problem,
        solution: this.props.solution,
        result: this.props.result,
        headline: this.props.headline
    }
    render() {
        return (
            <section className={cn(styles.steps)}>
                <div className="container">
                    <div className="headline">
                        <h2>Projekt<br/><span>{this.state.headline}</span></h2>
                    </div>
                    <div className={cn(styles.stepsWrapper)}>
                        <div className={cn(styles.stepsItem)}>
                            <h3>Das Problem</h3>
                            <p>{this.state.problem}</p>
                        </div>
                        <div className={cn(styles.stepsItem)}>
                            <h3>Die Lösung</h3>
                            <p>{this.state.solution}</p>
                        </div>
                        <div className={cn(styles.stepsItem)}>
                            <h3>Das Ergebnis</h3>
                            <p>{this.state.result}</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Steps;