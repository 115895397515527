import React, { Component } from 'react';
import cn from 'classnames';
import { Link, Navigate } from "react-router-dom";
import styles from '../scss/contact.module.scss';
import profile from '../assets/images/profile.webp';
import validator from 'validator';

import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Textarea from 'react-validation/build/textarea';
import Button from 'react-validation/build/button';
import profilePicture from '../assets/images/profile.webp';

import axios from 'axios';
const API_PATH = 'https://erik-ivanov.de/api.php?type=contact';

const required = (value) => {
  if (!value.toString().trim().length) {
      return 'Pflichtfeld';
  }
};
 
const email = (value) => {
  if (!validator.isEmail(value)) {
      return `${value} ist keine gültige E-Mail Adresse.`
  }
};

class Contact extends Component {
  constructor(props) {
      super(props);
      this.state = {
        firstname: '',
        lastname: '',
        useremail: '',
        usermessage: '',
        type: 'contact',
        redirect: false
      }
  }
  handleSubmit = ( event ) => {
    event.preventDefault();
    console.log(this.state);
    axios({
        method: 'post',
        url: `${API_PATH}`,
        headers: { 'content-type': 'application/json' },
        data: this.state
    })
    .then(res => {
      this.setState({ redirect: true });
    })
    .catch(error => this.setState({ error: error.message }));
  }
    render() { 
        return (
          <>
            <section className={cn(styles.contact)}>
                <div className={cn(styles.contactHeadline)}>
                  <h1>Wir helfen Ihnen<br/><span>gerne weiter</span></h1>
                </div>
                <div className={cn(styles.contactContainer)}>
                  <div className={cn(styles.contactForm)}>
                    <div className={cn(styles.contactInner)}>
                        <Form onSubmit={this.handleSubmit.bind(this)}>
                          <div class="row">
                            <div class="col">
                              <div className="form-row">
                                <label for="firstname">Vorname
                                  <Input id="firstname" className="form-input" placeholder="Max Mustermann *" onChange={e => this.setState({ firstname: e.target.value })} validations={[required]}/>
                                </label>
                              </div>
                            </div>
                            <div class="col">
                              <div className="form-row">
                                <label for="lastname">Nachname
                                  <Input id="lastname" className="form-input" placeholder="Max Mustermann *" onChange={e => this.setState({ lastname: e.target.value })} validations={[required]}/>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <label for="name">E-Mail
                              <Input id="name" className="form-input" placeholder="info@example.com *" onChange={e => this.setState({ useremail: e.target.value })} validations={[email, required]}/>
                            </label>
                          </div>
                          <div className="form-row">
                            <label for="name">Nachricht
                              <Textarea id="name" className="form-input" placeholder="Hi Erik, wir hätten gerne ... *" onChange={e => this.setState({ usermessage: e.target.value })} validations={[required]}></Textarea>
                            </label>
                          </div>
                          <div className="form-row">
                            <p className={cn(styles.contactPrivacy)}>Mit dem Absenden des Kontaktformulars erklären Sie sich damit einverstanden, dass Ihre Daten zur Bearbeitung Ihres Anliegens verwendet werden. Weitere Informationen und Widerrufshinweise finden Sie in der <Link to="/datenschutz/">Datenschutzerklärung</Link>.</p>
                          </div>
                          <div className="form-row">
                            <button type="submit" className="btn block gold">Absenden</button>
                          </div>
                        </Form>
                      </div>
                    </div>
                    <div className={cn(styles.contactSidebar)}>
                      <div className={cn(styles.contactSidebarInner)}>
                        <h3>Ihr <span>Ansprechpartner</span></h3>
                        <div className={cn(styles.contactProfile)}>
                          <div className={cn(styles.contactProfileImage)}>
                            <img src={profilePicture} alt="Erik Ivanov Portrait"/>
                          </div>
                          <div className={cn(styles.contactProfileMeta)}>
                            <h4>Erik Ivanov</h4>
                            <p><small>Geschäftsführer</small></p>
                          </div>
                        </div>
                        <p>
                          E-Mail: <a href="mailto:hallo@erik-ivanov.de" title="Nachricht schreiben">hallo@erik-ivanov.de</a><br/>
                          Telefon: <a href="tel:+4938548597862" title="Jetzt anrufen">0385 48597862</a>
                        </p>
                      </div>
                    </div>
                </div>
            </section>
            <section className={cn(styles.webdesignSection)}>
                <div className="container">
                    <div className="headline">
                        <h2><span>Regionales Webdesign</span><br/>von Erik Ivanov</h2>
                    </div>
                    <ul className={cn(styles.linkList)}>
                        <li>
                            <Link to="/webdesign-berlin/">Webdesign Berlin</Link>
                        </li>
                        <li>
                            <Link to="/webdesign-hamburg/">Webdesign Hamburg</Link>
                        </li>
                        <li>
                            <Link to="/webdesign-schwerin/">Webdesign Schwerin</Link>
                        </li>
                        <li>
                            <Link to="/webdesign-rostock/">Webdesign Rostock</Link>
                        </li>
                        <li>
                            <Link to="/webdesign-wismar/">Webdesign Wismar</Link>
                        </li>
                        <li>
                            <Link to="/webdesign-luebeck/">Webdesign Lübeck</Link>
                        </li>
                    </ul>
                </div>
            </section>
            { this.state.redirect && <Navigate to="/vielen-dank"/> }
          </>
        );
    }
}
 
export default Contact;