import React, { Component } from 'react';
import cn from 'classnames';
import styles from '../scss/testimonial.module.scss'

import julia from '../assets/images/julia-cosmetics-profile.jpg';
import placeholder from '../assets/images/profile-picture-placeholder.jpg';
import beglau from '../assets/images/bjoern-beglau-profile.jpg';
import trendline from '../assets/images/frank-kleinsorg-profile.jpg';
import wilts from '../assets/images/felix-keyser-profile.jpg';

class Testimonial extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        name: this.props.name,
        position: this.props.position,
        review: this.props.review,
        image: this.props.image
    }
    testimonialImage(person) {
        switch(person) {
            case 'julia':
                return julia
                break;
            case 'beglau':
                return beglau
                break;
            case 'trendline':
                return trendline
                break;
            case 'wilts':
                return wilts
                break;
            case 'placeholder':
                return placeholder
                break;
        }
    }
    render() { 
        return (
            <section className={cn(styles.testimonial)}>
                <div className="container">
                    <div className={cn(styles.testimonialWrapper)}>
                        <div className={cn(styles.testimonialReview)}>
                            {this.state.review}
                        </div>
                        <div className={cn(styles.testimonialAuthorWrapper)}>
                            <div className={cn(styles.testimonialAuthorImage)}>
                                <img src={this.testimonialImage(this.state.image)} alt={this.state.name + ' Profilbild'}/>
                            </div>
                            <div className={cn(styles.testimonialAuthor)}>
                                {this.state.name}
                                <span>{this.state.position}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Testimonial;