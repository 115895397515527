import React, { Component } from 'react';
import styles from '../scss/intro.module.scss';
import cn from 'classnames';
import { PopupButton } from "react-calendly";

class ProjectIntro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: this.props.page,
            title: this.props.title,
            image: this.props.image
        }
    }
    render() { 
        return (
            <section className={cn(styles.projectWrapper)}>
                <div className={cn(styles.introInner)}>
                    <div className={cn(styles.introCaption)}>
                        <h1 className={cn(styles.projectTitle)} dangerouslySetInnerHTML={{__html: this.state.title}}></h1>
                        <p className={cn(styles.introActions)}>
                            <a href="https://google.com" className="btn gold" title="Mehr erfahren">Mehr erfahren</a>
                            <a href="https://cloud.teamleader.eu/erik-ivanov/bookings/u/erik/t/30-min%C3%BCtiger-online-termin-mit-erik-ivanov/" className="btn link" title="Beratungsgespräch vereinbaren" target="_blank" ref="noopener noreferrer">Beratungsgespräch vereinbaren</a>
                        </p>
                        <img className={cn(styles.projectImage)} src={this.props.image} alt="Intro"/>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default ProjectIntro;