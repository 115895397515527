import React, { Component } from 'react';
import cn from 'classnames';
import styles from '../scss/service.module.scss';

class Service extends Component {
    render() { 
        return (
            <section className={cn(styles.service)}>
                <div className="container">
                    <div className="headline">
                        <h1>Ihr Ansprechpartner für<br/><span>ausgezeichneten Service.</span></h1>
                    </div>
                    <div className={cn(styles.serviceWrapper)}>
                        <div className={cn(styles.serviceItem)}>
                            <div className={cn(styles.serviceItemInner)}>
                                <div className={cn(styles.serviceItemHeadlineWrapper)}>
                                    <div className={cn(styles.serviceItemHeadline)}>
                                        <h3>Design</h3>
                                    </div>
                                </div>
                                <div className={cn(styles.serviceItemContent)}>
                                    <p>Benutzerfreundliche und funktionale Webseiten für Ihr Unternehmen. Wir verschaffen Ihrem Unternehmen ein einzigartiges und einprägsames Erscheinungsbild.</p>
                                </div>
                            </div>
                        </div>
                        <div className={cn(styles.serviceItem)}>
                            <div className={cn(styles.serviceItemInner)}>
                                <div className={cn(styles.serviceItemHeadlineWrapper)}>
                                    <div className={cn(styles.serviceItemHeadline)}>
                                        <h3>Entwicklung</h3>
                                    </div>
                                </div>
                                <div className={cn(styles.serviceItemContent)}>
                                    <p>Unsere professionellen Entwickler programmieren Ihre Webseite nach neusten Standards und beachten ebenfalls rechtliche Vorgaben. Ladezeiten und Benutzerfreundlichkeit stehen dabei im Fokus.</p>
                                </div>
                            </div>
                        </div>
                        <div className={cn(styles.serviceItem)}>
                            <div className={cn(styles.serviceItemInner)}>
                                <div className={cn(styles.serviceItemHeadlineWrapper)}>
                                    <div className={cn(styles.serviceItemHeadline)}>
                                        <h3>Volles Paket</h3>
                                    </div>
                                </div>
                                <div className={cn(styles.serviceItemContent)}>
                                    <p>Mehr Leistung für Ihre neue Webseite? Auf Wunsch kümmern wir uns um die Vermarktung Ihrer Produkte oder Dienstleistungen. Mit unserer bewährten Suchmaschinenoptimierung werden Sie in kürzester Zeit im Netz gefunden.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Service;