import React, { Component } from 'react';
import styles from '../scss/scroll.module.scss';
import cn from 'classnames'

class ScrollIndicator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progressHeight: 0
        };
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    handleScroll = () => {
        let offset = document.body.scrollTop || document.documentElement.scrollTop;
        let windowHeight = window.innerHeight;
        let height = document.body.scrollHeight;
        let progress = (offset / (height - windowHeight)) * 100;
        this.setState({ progressHeight: progress });
    }
    state = {  }
    render() { 
        return (
            <div className={cn(styles.pagePosition)}>
                <div className={cn(styles.pageBar)}></div>
                <div className={cn(styles.pageProgress)} style={{height: this.state.progressHeight + '%'}}></div>
            </div>
        );
    }
}
 
export default ScrollIndicator;