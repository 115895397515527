import React, { Component } from 'react';
import styles from '../scss/stats.module.scss';
import cn from 'classnames';
import musiceggertLogo from '../assets/logos/me-logo.svg';
import beglauLogo from '../assets/logos/beglau.svg';
import juliaLogo from '../assets/logos/julia-cosmetics-logo.svg';
import wiltsLogo from '../assets/logos/wilts-keyser.svg';
import buckLogo from '../assets/logos/svenbuck-logo.svg';
import trendlineLogo from '../assets/logos/trendline-logo.svg';

class ProjectInfo extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        logo: this.props.logo,
        headline: this.props.headline,
        text: this.props.text,
        url: this.props.url
    }
    companyLogo( company ) {
        switch(company) {
            case 'julia':
                return juliaLogo
                break;
            case 'musiceggert':
                return musiceggertLogo
                break;
            case 'beglau':
                return beglauLogo
                break;
            case 'wilts':
                return wiltsLogo
                break;
            case 'svenbuck':
                return buckLogo
                break;
            case 'trendline':
                return trendlineLogo
                break;
        }
    }
    companyTime( company ) {
        switch(company) {
            case 'julia':
                return 25
                break;
            case 'musiceggert':
                return 55
                break;
            case 'beglau':
                return 50
                break;
            case 'wilts':
                return 20
                break;
            case 'svenbuck':
                return 40
                break;
            case 'trendline':
                return 35
                break;
        }
    }
    companyCode( company ) {
        switch(company) {
            case 'julia':
                return '10.000'
                break;
            case 'musiceggert':
                return '1M.'
                break;
            case 'beglau':
                return '1M.'
                break;
            case 'wilts':
                return '10.000'
                break;
            case 'svenbuck':
                return '25.000'
                break;
            case 'trendline':
                return '20.000'
                break;
        }
    }
    companyCustomer( company ) {
        switch(company) {
            case 'julia':
                return '10.000'
                break;
            case 'musiceggert':
                return '1M.'
                break;
            case 'beglau':
                return '1M.'
                break;
            case 'wilts':
                return '1.000'
                break;
            case 'svenbuck':
                return '10.000'
                break;
            case 'trendline':
                return '18.000'
                break;
        }
    }
    render() { 
        return (
            <section className={cn(styles.stats)}>
                <div className={cn(styles.statsWrapper)}>
                    <div className={cn(styles.statsItem)}>
                        100<div className={cn(styles.statsValue)}>/100</div>
                        <span>PageSpeed Bewertung</span>
                    </div>
                    <div className={cn(styles.statsItem)}>
                        {this.companyTime(this.state.logo)}
                        <span>Stunden</span>
                    </div>
                    <div className={cn(styles.statsItem)}>
                        {this.companyCustomer(this.state.logo)}
                        <span>Erreichte Kunden</span>
                    </div>
                    <div className={cn(styles.statsItem)}>
                        35%
                        <span>Umsatzsteigerung</span>
                    </div>
                    <div className={cn(styles.statsItem)}>
                        {this.companyCode(this.state.logo)}
                        <span>Codezeilen</span>
                    </div>
                </div>
                <div className="container">
                    <div className={cn(styles.statsInner)}>
                        <div className={cn(styles.statsRow)}>
                            <div className={cn(styles.statsLogo)}>
                                <div className={cn(styles.statsLogoInner)}>
                                    <img className="aligncenter" src={this.companyLogo(this.state.logo)} alt={this.state.headline + ' Logo'}/>
                                </div>
                            </div>
                            <div className={cn(styles.statsText)}>
                                <h4 className={cn(styles.statsHeadline)}>{this.state.headline}</h4>
                                <p>{this.state.text}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default ProjectInfo;