import React, { Component } from 'react';
import qs from 'qs';
import cn from 'classnames';
import styles from '../scss/google.module.scss';
import axios from 'axios';

import warningIcon from '../assets/icons/warning.svg';
import checkmarkIcon from '../assets/icons/checkmark-circle.svg';

import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';

const GOOGLE_API_PATH = 'http://localhost:3001/';

class GoogleFontsScanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
          url: '',
          status: '',
          scanStatus: '',
          formVisible: true
        }
    }
    handleSubmit = ( event ) => {
        event.preventDefault();
        const data = { 'url': this.state.url }
        axios({
            method: 'POST',
            url: `${GOOGLE_API_PATH}`,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
        })
        .then(result => {
            this.setState({
                status: result.data.status,
                scanStatus: result.data.scanStatus,
                formVisible: false
            });
        })
        .catch(error => this.setState({ error: error.message }));
    }
    render() {
        return (
            <section className={cn(styles.googleFontsScanner)}>
                <div className="small-container">
                    <div className="headline">
                        <h2>Haben Sie <br/><span>Google Fonts</span> korrekt eingebunden?</h2>
                    </div>
                    <p>Geben Sie Abmahnern keine Chance!</p>
                    {(this.state.formVisible) ?
                    <Form onSubmit={this.handleSubmit.bind(this)}>
                        <div className={cn(styles.googleFontsForm)}>
                            <div className={cn(styles.googleFontsDomain)}>
                                <Input type="text" placeholder="ihredomain.de*" onChange={e => this.setState({ url: e.target.value })}/>
                            </div>
                            <button type="submit" className={cn(styles.googleFontsSubmit) + ' btn gold animated'}>Webseite prüfen</button>                        </div>
                    </Form>
                    :
                    ''
                    }
                    {(this.state.scanStatus && !this.state.formVisible) ?
                    <div className={cn(styles.googleFontsResult)}>
                        <div className="row">
                            <div>
                                <img src={warningIcon} height="160" width="160"/>
                            </div>
                            <div className="col">
                                <h4>Ihr Ergebnis für <span>{this.state.url}</span><br/>Ihre Webseite ist <span>abmahngefährdet</span>, da Google Fonts nicht DSGVO-konform installiert ist.</h4>
                                <a href="" className="btn gold animated" title="Weitere Informationen">Weitere Informationen</a>
                            </div>
                        </div>
                    </div>
                    :
                        ''
                    }
                    {(!this.state.scanStatus && !this.state.formVisible) ?
                    <div className={cn(styles.googleFontsResult)}>
                        <div className="row">
                            <div>
                                <img src={checkmarkIcon} height="160" width="160"/>
                            </div>
                            <div className="col">
                                <h4>Ihr Ergebnis für <span>{this.state.url}</span><br/><span>Keine Probleme</span> gefunden.</h4>
                            </div>
                        </div>
                    </div>
                    :
                        ''
                    }
                </div>
            </section>
        );
    }
}
 
export default GoogleFontsScanner;