import React, { Component } from 'react';
import handleViewport from 'react-in-viewport';
import cn from 'classnames';
import styles from '../scss/clients.module.scss';

import dreilaut from '../assets/logos/dreilaut.svg';
import beglau from '../assets/logos/beglau.svg';
import airbeat from '../assets/logos/airbeat.svg';
import trendline from '../assets/logos/trendline.svg';
import svenbuck from '../assets/logos/svenbuck.svg';
import musiceggert from '../assets/logos/musiceggert.svg';
import goldenlegprojects from '../assets/logos/goldenlegprojects.svg';
import skycafe from '../assets/logos/skycafe.svg';
import superior from '../assets/logos/superior-logo.svg';
import ladypolitan from '../assets/logos/ladypolitan-logo.svg';

class Clients extends Component {
        getStyle() {
        const { inViewport, enterCount } = this.props;
                if (inViewport && enterCount === 1) {
                return { WebkitTransition: '0.5s ease-in-out' };
                } else if (!inViewport && enterCount < 1) {
                return { WebkitTransition: 'none', opacity: '0' };
                } else {
                return {};
                }
        }
    render() { 
        return (
            <section className={cn(styles.clients)} style={this.getStyle()}>
                <div className="container">
                    <div className={cn(styles.clientsHeadline)}>Unsere Kunden</div>
                    <div className={cn(styles.clientsWrapper)}>
                        <div className={cn(styles.clientsItem)}>
                                <img src={dreilaut} alt="DREILAUT UG"/>
                        </div>
                        <div className={cn(styles.clientsItem)}>
                                <img src={beglau} alt="Beglau Wärmepumpen GmbH"/>
                        </div>
                        <div className={cn(styles.clientsItem)}>
                                <img src={airbeat} alt="Airbeat One Dance Festival"/>
                        </div>
                        <div className={cn(styles.clientsItem)}>
                                <img src={trendline} alt="Trendline Traumhaus"/>
                        </div>
                        <div className={cn(styles.clientsItem)}>
                                <img src={svenbuck} alt="Sven Buck"/>
                        </div>
                        <div className={cn(styles.clientsItem)}>
                                <img src={musiceggert} alt="Music Eggert"/>
                        </div>
                        <div className={cn(styles.clientsItem)}>
                                <img src={goldenlegprojects} alt="Goldenleg Projects"/>
                        </div>
                        <div className={cn(styles.clientsItem)}>
                                <img src={skycafe} alt="Sky Café Schwerin"/>
                        </div>
                        <div className={cn(styles.clientsItem)}>
                                <img src={superior} alt="Superior Berlin"/>
                        </div>
                        <div className={cn(styles.clientsItem)}>
                                <img src={ladypolitan} alt="Ladypolitan"/>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Clients;