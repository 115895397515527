import React, { Component } from 'react';
import cn from 'classnames';
import styles from '../scss/projects.module.scss';
import airbeatScreenshot from '../assets/images/airbeat-one-screenshot.webp';
import juliaScreenshot from '../assets/images/julia-cosmetics-screenshot.webp';
import wiltsScreenshot from '../assets/images/wilts-keyser-screenshot.webp';
import beglauScreenshot from '../assets/images/beglau-screenshot.webp';
import trendlineScreenshot from '../assets/images/trendline-traumhaus-screenshot.webp';

class ProjectScreenshot extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        project: this.props.project,
        url: this.props.url
    }
    projectImage( project ) {
        switch(project) {
            case 'wilts':
                return wiltsScreenshot
                break;
            case 'airbeat':
                return airbeatScreenshot
                break;
            case 'julia':
                return juliaScreenshot
                break;
            case 'beglau':
                return beglauScreenshot
                break;
            case 'trendline':
                return trendlineScreenshot
                break;
        }
    }
    render() { 
        return (
            <section>
                <div className="small-container">
                <p className="aligncenter">
                    <img className="aligncenter" src={this.projectImage(this.state.project)} alt="Screenshot"/>
                </p>
                <p className="aligncenter">  
                    <a href={'https://' + this.state.url} target="_blank" className="btn link" title="Webseite ansehen">
                        www.{this.state.url}
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.768" height="15.537" viewBox="0 0 15.768 15.537">
                        <path id="arrow-forward" d="M107.413,112l6.354,6.354-6.354,6.354m5.472-6.354H100" transform="translate(-99 -110.586)" fill="none" stroke="#DBAC52" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                        </svg>
                    </a>
                </p>
                </div>
            </section>
        );
    }
}
 
export default ProjectScreenshot;