import React, { Component } from 'react';
import styles from '../scss/intro.module.scss';
import cn from 'classnames';
import introImage from '../assets/images/homepage.webp';
import marketingImage from '../assets/images/onlinemarketing.webp';
import googlePartner from '../assets/images/googlepartner.webp';
import { PopupButton } from "react-calendly";

class Intro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: this.props.page,
            googlePartner: this.props.googlePartner,
            ratingStars: this.props.ratingStars,
            title: this.props.title,
            image: this.props.image
        }
    }
    scrollDown(e) {
        e.preventDefault();
        console.log('scrolled');
        let pageHeight = window.innerHeight;
        window.scrollBy({
            top: pageHeight,
            left: 0,
            behavior: 'smooth'
        });
    }
    render() { 
        return (
            <section className={cn(styles.introWrapper)}>
                <div className={cn(styles.introInner)}>
                    <div className={cn(styles.introCaption)}>
                        {(this.state.googlePartner) ?
                            <div className={cn(styles.googlePartner)}>
                                <img src={googlePartner} alt="Google Partner Logo"/>
                            </div>
                        :
                            null
                        }
                        {(this.state.ratingStars) ?
                            <div className={cn(styles.review)}>
                                <div className={cn(styles.reviewStars)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24.271" height="22.658" viewBox="0 0 24.271 22.658">
                                        <path id="star" d="M35.153,54.64a.809.809,0,0,1-.475-.152l-6.5-4.715-6.5,4.715a.809.809,0,0,1-1.241-.914l2.536-7.513-6.574-4.508a.809.809,0,0,1,.455-1.477h8.11l2.447-7.532a.809.809,0,0,1,1.539,0l2.447,7.535H39.5a.809.809,0,0,1,.458,1.477l-6.576,4.506,2.535,7.511a.809.809,0,0,1-.765,1.068Z" transform="translate(-16.041 -31.985)" fill="#dbac52"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24.271" height="22.658" viewBox="0 0 24.271 22.658">
                                        <path id="star" d="M35.153,54.64a.809.809,0,0,1-.475-.152l-6.5-4.715-6.5,4.715a.809.809,0,0,1-1.241-.914l2.536-7.513-6.574-4.508a.809.809,0,0,1,.455-1.477h8.11l2.447-7.532a.809.809,0,0,1,1.539,0l2.447,7.535H39.5a.809.809,0,0,1,.458,1.477l-6.576,4.506,2.535,7.511a.809.809,0,0,1-.765,1.068Z" transform="translate(-16.041 -31.985)" fill="#dbac52"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24.271" height="22.658" viewBox="0 0 24.271 22.658">
                                        <path id="star" d="M35.153,54.64a.809.809,0,0,1-.475-.152l-6.5-4.715-6.5,4.715a.809.809,0,0,1-1.241-.914l2.536-7.513-6.574-4.508a.809.809,0,0,1,.455-1.477h8.11l2.447-7.532a.809.809,0,0,1,1.539,0l2.447,7.535H39.5a.809.809,0,0,1,.458,1.477l-6.576,4.506,2.535,7.511a.809.809,0,0,1-.765,1.068Z" transform="translate(-16.041 -31.985)" fill="#dbac52"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24.271" height="22.658" viewBox="0 0 24.271 22.658">
                                        <path id="star" d="M35.153,54.64a.809.809,0,0,1-.475-.152l-6.5-4.715-6.5,4.715a.809.809,0,0,1-1.241-.914l2.536-7.513-6.574-4.508a.809.809,0,0,1,.455-1.477h8.11l2.447-7.532a.809.809,0,0,1,1.539,0l2.447,7.535H39.5a.809.809,0,0,1,.458,1.477l-6.576,4.506,2.535,7.511a.809.809,0,0,1-.765,1.068Z" transform="translate(-16.041 -31.985)" fill="#dbac52"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24.271" height="22.658" viewBox="0 0 24.271 22.658">
                                        <path id="star" d="M35.153,54.64a.809.809,0,0,1-.475-.152l-6.5-4.715-6.5,4.715a.809.809,0,0,1-1.241-.914l2.536-7.513-6.574-4.508a.809.809,0,0,1,.455-1.477h8.11l2.447-7.532a.809.809,0,0,1,1.539,0l2.447,7.535H39.5a.809.809,0,0,1,.458,1.477l-6.576,4.506,2.535,7.511a.809.809,0,0,1-.765,1.068Z" transform="translate(-16.041 -31.985)" fill="#dbac52"/>
                                    </svg>
                                </div>
                                5,0 Bewertungen
                            </div>
                        :
                            null
                        }
                        <h1 dangerouslySetInnerHTML={{__html: this.state.title}}></h1>
                        <p className={cn(styles.introActions)}>
                            <button onClick={(e) => this.scrollDown(e)} className="btn gold" title="Mehr erfahren">Mehr erfahren</button>
                            <script src="https://meeting.teamleader.eu/contact-widget-public/script.js?contactWidgetId=76d9c108fb6f4e429e7bc497d0d67d5b" async></script>
                        </p>
                        <img className={cn(styles.introImage)} src={introImage} alt="Intro"/>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Intro;