import { UPDATE } from '../types/configurator.types';

let configurator = {
    configurator: {
        hasWebsite: false,
        websiteURL: null,
        companyName: null,
        hasLogo: false,
        branch: null,
        googleBusiness: false,
        basePrice: 4400,
        brancheSurcharge: 0,
        sitesSurcharge: 0,
        googleSurcharge: 0,
        estimatedTotal: false,
        estimatedSurcharge: false,
        selectedBranch: 'Beratung',
        branch: {
            advisory: true,
            service: false,
            gastronomy: false,
            health: false,
            trade: false,
            craft: false,
            industry: false,
            other: false
        },
        sites: {
            onethree: true,
            foursix: false,
            sevennine: false,
            morenine: false
        },
        price: 0,
    }
};

function rootReducer(state = configurator, action) {
  switch (action.type) {
        case UPDATE:
            return {
                configurator: {
                    ...state.configurator,
                    ...action.configurator
                }
            };
        default:
            return state;
    }
}

export default rootReducer;