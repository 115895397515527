import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Helmet from 'react-helmet';
import Error from './Error';
import Footer from './Footer';
import BlogIntro from './BlogIntro';
import { Redirect, useParams, redirect } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import cn from 'classnames';
import styles from '../scss/blogContent.module.scss';

const BlogContent = () => {
    const slugPath = useParams();
    const [isLoading, setLoading] = useState(true);
    const [state, setState] = useState({});

    useEffect(() => {
        axios.get(`https://cms.erik-ivanov.de/wp-json/wp/v2/posts?slug=${slugPath.slug}&_embed`).then(response => {
            setState(response.data[0]);
            setLoading(false);
        });
    }, []);

    if (isLoading) {
        return <></>;
    }
    return(
        (!isLoading && state === undefined) ? <Error/> :
        <>
            <Helmet>
                <title>{state.title.rendered} | Erik Ivanov</title>
                <meta name="description" content="Webdesign in Schwerin von Erik Ivanov. Erreichen Sie mehr Sichtbarkeit und Erfolg im Internet. Jetzt kostenfreies Angebot sichern!" />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <link rel="canonical" href={`https://erik-ivanov.de/blog/${slugPath.slug}/`} />
                <meta property="og:locale" content="de_DE" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={state.title.rendered + ' | Erik Ivanov'} />
                <meta property="og:description" content="Webdesign in Schwerin von Erik Ivanov. Erreichen Sie mehr Sichtbarkeit und Erfolg im Internet. Jetzt kostenfreies Angebot sichern!" />
                <meta property="og:url" content={`https://erik-ivanov.de/blog/${slugPath.slug}/`} />
                <meta property="og:site_name" content="Erik Ivanov" />
                <meta property="article:modified_time" content="2020-04-01T20:57:53+00:00" />
                <meta property="og:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="627" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={state.title.rendered + ' | Erik Ivanov'} />
                <meta name="twitter:description" content="Webdesign in Schwerin von Erik Ivanov. Erreichen Sie mehr Sichtbarkeit und Erfolg im Internet. Jetzt kostenfreies Angebot sichern!" />
                <meta name="twitter:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
            </Helmet>
            <BlogIntro image={state._embedded['wp:featuredmedia'][0].media_details.sizes} title={state.title.rendered} date={state.date} slug={slugPath.slug}/>
            <article className={cn(styles.blogContent)} dangerouslySetInnerHTML={{ __html: state.content.rendered }}></article>
            <script type="application/ld+json">
                {`
                    "@context": "https://schema.org",
                    "@type": "Article",
                    "mainEntityOfPage": {
                        "@type": "WebPage",
                        "@id": "https://erik-ivanov.de/blog/${slugPath.slug}"
                    },
                    "headline": "${state.title.rendered}",
                    "description": "${state.excerpt.rendered}",
                    "image": "https://cms.erik-ivanov.de/wp-content/uploads/2023/05/google-fonts-abmahnungen-blogbeitrag-815x458.jpg",  
                    "author": {
                        "@type": "Person",
                        "name": "Erik Ivanov",
                        "url": "https://erik-ivanov.de/"
                    },  
                    "publisher": {
                        "@type": "Organization",
                        "name": "Erik Ivanov",
                        "logo": {
                        "@type": "ImageObject",
                        "url": ""
                        }
                    },
                    "datePublished": "${state.date.split('T')[0]}",
                    "dateModified": "${state.modified.split('T')[0]}"
                `}
            </script>
            <Footer/>
        </>
    );
}
 
export default BlogContent;