import React, { Component } from 'react';
import cn from 'classnames';
import styles from '../scss/projects.module.scss';
import projectImage from '../assets/images/wilts-keyser-main-laptop.webp';
import projectImage2 from '../assets/images/wilts-keyser-mobil-main.webp';
import projectImage3 from '../assets/images/trendline-traumhaus-main-laptop.jpg';
import projectImage4 from '../assets/images/trendline-traumhaus-mobil-main.jpg';
import projectImage5 from '../assets/images/beglau-laptop-main.jpg';
import projectImage6 from '../assets/images/beglau-main-mobil.jpg';
import projectImage7 from '../assets/images/svenbuck-main-laptop.jpg';
import projectImage8 from '../assets/images/svenbuck-mobil-main.jpg';
import projectImage9 from '../assets/images/juliacosmetics-main-laptop.jpg';
import projectImage10 from '../assets/images/juliacosmetics-mobil-main.jpg';
import projectImage11 from '../assets/images/airbeatone-main-laptop.webp';
import projectImage12 from '../assets/images/airbeatone-mobil-main.webp';
import projectImage13 from '../assets/images/trendline-traumhaus-screenshot.webp';
import projectImage14 from '../assets/images/julia-cosmetics-screenshot.webp';
import projectImage15 from '../assets/images/beglau-webseite-screenshot-desktop.webp';
import projectImage16 from '../assets/images/airbeat-one-webseite-screenshot-desktop.webp';
import projectImage17 from '../assets/images/wilts-keyser-webseite-screenshot-desktop.webp';
import projectImage18 from '../assets/images/svenbuck-webseite-screenshot-desktop.webp';
import projectImage19 from '../assets/images/airbeatone-main-laptop-md.webp';
import projectImage20 from '../assets/images/airbeatone-main-laptop-sm.webp';
import projectImage21 from '../assets/images/airbeatone-mobil-main-md.webp';
import projectImage22 from '../assets/images/airbeat-one-webseite-screenshot-desktop-md.webp';
import projectImage23 from '../assets/images/airbeat-one-webseite-screenshot-desktop-sm.webp';
import projectImage24 from '../assets/images/airbeatone-mobil-main-sm.webp';
import projectImage25 from '../assets/images/wilts-keyser-main-laptop-md.webp';
import projectImage26 from '../assets/images/wilts-keyser-main-laptop-sm.webp';
import projectImage27 from '../assets/images/wilts-keyser-mobil-main-md.webp';
import projectImage28 from '../assets/images/wilts-keyser-mobil-main-sm.webp';
import projectImage29 from '../assets/images/wilts-keyser-webseite-screenshot-desktop-md.webp';
import projectImage30 from '../assets/images/wilts-keyser-webseite-screenshot-desktop-sm.webp';
import projectImage31 from '../assets/images/beglau-laptop-main-md.webp';
import projectImage32 from '../assets/images/beglau-laptop-main-sm.webp';
import projectImage33 from '../assets/images/beglau-main-mobil-md.webp';
import projectImage34 from '../assets/images/beglau-main-mobil-sm.webp';
import projectImage35 from '../assets/images/beglau-webseite-screenshot-desktop-md.webp';
import projectImage36 from '../assets/images/beglau-webseite-screenshot-desktop-sm.webp';
import projectImage37 from '../assets/images/juliacosmetics-main-laptop-md.webp';
import projectImage38 from '../assets/images/juliacosmetics-main-laptop-sm.webp';
import projectImage39 from '../assets/images/juliacosmetics-mobil-main-md.webp';
import projectImage40 from '../assets/images/juliacosmetics-mobil-main-sm.webp';
import projectImage41 from '../assets/images/trendline-traumhaus-main-laptop-md.webp';
import projectImage42 from '../assets/images/trendline-traumhaus-main-laptop-sm.webp';
import projectImage43 from '../assets/images/trendline-traumhaus-mobil-main-md.webp';
import projectImage44 from '../assets/images/trendline-traumhaus-mobil-main-sm.webp';
import projectImage45 from '../assets/images/trendline-traumhaus-screenshot-md.webp';
import projectImage46 from '../assets/images/trendline-traumhaus-screenshot-sm.webp';
import projectImage47 from '../assets/images/julia-cosmetics-screenshot-md.webp';
import projectImage48 from '../assets/images/julia-cosmetics-screenshot-sm.webp';
import projectImage49 from '../assets/images/svenbuck-main-laptop-md.webp';
import projectImage50 from '../assets/images/svenbuck-main-laptop-sm.webp';
import projectImage51 from '../assets/images/svenbuck-mobil-main-sm.webp';
import projectImage52 from '../assets/images/svenbuck-mobil-main-md.webp';
import projectImage53 from '../assets/images/svenbuck-webseite-screenshot-desktop-sm.webp';
import projectImage54 from '../assets/images/svenbuck-webseite-screenshot-desktop-md.webp';

let images = [];

class ProjectsImages extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        project: this.props.project,
        url: this.props.url
    }
    componentWillMount() {
        switch( this.state.project ) {
            case 'julia':
                this.setState({
                    images: [
                        { src: projectImage38, alt: "Julia Cosmetics Webseite Desktop Ansicht", images: [ { src: projectImage9, size: 'sm' }, { src: projectImage37, size: 'md' } ] },
                        { src: projectImage39, alt: "Julia Cosmetics Webseite Mobil Ansicht", images: [ { src: projectImage10, size: 'sm' }, { src: projectImage40, size: 'md' } ] },
                        { src: projectImage48, alt: "Julia Cosmetics Webseite Screenshot Desktop Ansicht", images: [ { src: projectImage14, size: 'sm' }, { src: projectImage47, size: 'md' } ] }
                    ]
                });
                break;
            case 'wilts':
                this.setState({
                    images: [
                        { src: projectImage26, alt: "Wilts Keyser Webseite Desktop Ansicht", images: [ { src: projectImage, size: 'sm' }, { src: projectImage25, size: 'md' } ] },
                        { src: projectImage28, alt: "Wilts Keyser Webseite Mobil Ansicht", images: [ { src: projectImage2, size: 'sm' }, { src: projectImage, size: 'md' } ] },
                        { src: projectImage30, alt: "Wilts Keyser Webseite Screenshot Desktop Ansicht", images: [ { src: projectImage17, size: 'sm' }, { src: projectImage29, size: 'md' } ] }
                    ]
                });
                break;
            case 'trendline':
                this.setState({
                    images: [
                        { alt: "Trendline Das Haus Desktop Ansicht", src: projectImage42, images: [ { src: projectImage3, size: 'sm' }, { src: projectImage41, size: 'md' } ] },
                        { alt: "Trendline Das Haus Mobil Ansicht", src: projectImage44, images: [ { size: 'sm', src: projectImage4, }, { size: 'md', src: projectImage43 } ] },
                        { alt: "Trendline Das Haus Webseite Screenshot Desktop Ansicht", src: projectImage46, images: [ { src: projectImage13, size: 'sm' }, { src: projectImage45, size: 'md' } ] }
                    ]
                });
                break;
            case 'beglau':
                this.setState({
                    images: [
                        { src: projectImage32, alt: "Beglau Wärmepumpen GmbH Webseite Desktop Ansicht", images: [ { src: projectImage5, size: 'sm' }, { src: projectImage31, size: 'md' } ] },
                        { src: projectImage34, alt: "Beglau Wärmepumpen GmbH Webseite Mobil Ansicht", images: [ { src: projectImage6, size: 'sm' }, { src: projectImage33, size: 'md' } ] },
                        { src: projectImage36, alt: "Beglau Wärmepumpen GmbH Webseite Screenshot Desktop Ansicht", images: [ { src: projectImage15, size: 'sm' }, { src: projectImage35, size: 'md' } ] }
                    ]
                });
                break;
            case 'svenbuck':
                this.setState({
                    images: [
                        { src: projectImage50, alt: "Sven Buck Webseite Desktop Ansicht", images: [ { src: projectImage7, size: 'sm' }, { src: projectImage49, size: 'md' } ] },
                        { src: projectImage52, alt: "Sven Buck Webseite Mobil Ansicht", images: [ { src: projectImage8, size: 'sm' }, { src: projectImage51, size: 'md' } ] },
                        { src: projectImage54, alt: "Sven Buck Webseite Screenshot Desktop Ansicht", images: [ { src: projectImage18, size: 'sm' }, { src: projectImage53, size: 'md' } ] }
                    ]
                });
                break;
            case 'airbeat':
                this.setState({
                    images: [
                        { alt: "Airbeat One Webseite Desktop Ansicht", src: projectImage19, images: [ { src: projectImage11, size: 'sm' }, { src: projectImage20, size: 'md' } ] },
                        { alt: "Airbeat One Webseite Mobil Ansicht", src: projectImage21, images: [ { size: 'sm', src: projectImage12, }, { size: 'md', src: projectImage24 } ] },
                        { alt: "Airbeat One Webseite Screenshot Desktop Ansicht", src: projectImage23, images: [ { src: projectImage16, size: 'sm' }, { src: projectImage22, size: 'md' } ] }
                    ]
                });
                break;
        }
    }
    render() { 
        return (
            <section className={cn(styles.projectsImages)}>
                <div className="small-container">
                    {(this.state.images.map((image) => 
                        <p>
                            <picture>
                                {(image.images.map((image) =>
                                    <>
                                        <source media={(image.size == 'md') ? '(min-width: 767px)' : '(min-width: 480px)' } srcset={image.src}/>
                                    </>
                                ))}
                                <img src={image.src} alt={image.alt} className="img-responsive"/>
                            </picture>
                        </p>
                    ))}
                    <p className="aligncenter">  
                        <a href={'https://' + this.state.url} target="_blank" className="btn link" title="Webseite ansehen">
                            www.{this.state.url}
                            <svg xmlns="http://www.w3.org/2000/svg" width="15.768" height="15.537" viewBox="0 0 15.768 15.537">
                            <path id="arrow-forward" d="M107.413,112l6.354,6.354-6.354,6.354m5.472-6.354H100" transform="translate(-99 -110.586)" fill="none" stroke="#DBAC52" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                            </svg>
                        </a>
                    </p>
                </div>
            </section>
        );
    }
}
 
export default ProjectsImages;