import React, { Component } from 'react';
import { Routes, Route, Link } from "react-router-dom";
import cn from 'classnames';
import styles from '../scss/welcome.module.scss';
import welcome from '../assets/images/welcome.webp';

class Welcome extends Component {
    render() { 
        return (
            <section className={cn(styles.welcome)}>
                <div className="container">
                    <div className={cn(styles.welcomeImage)}>
                        <img src={welcome} alt="Welcome Image"/>
                    </div>
                    <div className={cn(styles.welcomeContent)}>
                        <div className={cn(styles.welcomeInner)}>
                            <div className={cn(styles.welcomeHeadline)}>
                                <h2>Viel mehr<br/>als nur<br/><span>Webseiten.</span></h2>
                            </div>
                            <div className={cn(styles.welcomeText)}>
                                <p>Bereits seit über 9 Jahren gestalte und programmiere ich Webseiten für jeden Zweck. In dieser Zeit durfte ich viel Erfahrung mit unterschiedlichen Projekten sammeln und konnte mich auf bekannte Tools, wie Adobe Creative Cloud oder WordPress spezialisieren. Egal ob Webdesign, Flyer oder Visitenkarten – faire Preise & professionelle Leistungen.</p>
                            </div>
                            <div className={cn(styles.welcomeAction)}>
                                <p>
                                    <a href="https://cloud.teamleader.eu/erik-ivanov/bookings/u/erik/t/30-min%C3%BCtiger-online-termin-mit-erik-ivanov/" className="btn outline" title="Beratungsgespräch vereinbaren" target="_blank" ref="noopener noreferrer">Beratungsgespräch vereinbaren</a>
                                    <Link to="/referenzen/" className="btn link" title="Unsere Referenzen">Unsere Referenzen</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Welcome;