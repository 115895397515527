import React, { Component } from 'react';
import styles from '../scss/intro.module.scss';
import cn from 'classnames';
import introImage from '../assets/images/homepage.webp';
import marketingImage from '../assets/images/onlinemarketing.webp';
import googlePartner from '../assets/images/googlepartner.webp';
import { PopupButton } from "react-calendly";
import { Link } from 'react-router-dom';

class Error extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() { 
        return (
            <section className={cn(styles.introWrapper)}>
                <div className={cn(styles.introInner)}>
                    <div className={cn(styles.introCaption)}>
                        <h1>Fehler #404</h1>
                        <p>Die aufgerufene Seite existiert leider nicht.<br/>Benötigen Sie weitere Unterstützung?<br/>Zögern Sie nicht und kontaktieren Sie uns kostenlos!</p>
                        <p className={cn(styles.introActions)}>
                            <Link to="/" className="btn gold" title="Zurück zur Startseite">Zurück zur Startseite</Link>
                            <a href="https://cloud.teamleader.eu/erik-ivanov/bookings/u/erik/t/30-min%C3%BCtiger-online-termin-mit-erik-ivanov/" target="_blank" ref="noopener noreferrer" className="btn link" title="Beratungsgespräch vereinbaren">Beratungsgespräch vereinbaren</a>
                        </p>
                        <img className={cn(styles.introImage)} src={introImage} alt="Intro"/>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Error;