import React, { Component } from 'react';
import { Routes, Route, Link } from "react-router-dom";
import cn from 'classnames';
import styles from '../scss/page.module.scss';

class ThankYouPage extends Component {
    render() { 
        return (
            <section className={cn(styles.page)}>
                <div className="small-container">
                    <div className="headline">
                        <h1>Vielen Dank</h1>
                    </div>
                    <h2>Vereinbaren Sie jetzt ein Online-Meeting</h2>
                    <iframe style={{width: '100%', height: '600px'}} src="https://meeting.teamleader.eu/embed/book/erik-ivanov/u/erik/t/30-min%C3%BCtiger-online-termin-mit-erik-ivanov/" frameBorder="0"></iframe>
                </div>
            </section>
        );
    }
}
 
export default ThankYouPage;