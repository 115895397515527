import React, { Component } from 'react';
import cn from 'classnames';
import styles from '../scss/testimonial.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/autoplay';
import issasaleh from '../assets/images/issa-saleh-profile.jpg';
import christophkonitzer from '../assets/images/christoph-konitzer-profile.jpg';
import juliahuttmann from '../assets/images/julia-cosmetics-profile.jpg';
import felixmichalski from '../assets/images/felix-michalski-profile.jpg';
import bjornbeglau from '../assets/images/bjoern-beglau-profile.jpg';
import stephannette from '../assets/images/stephan-nette-profile.jpg';
import juanfladl from '../assets/images/juan-fladl-profile.jpg';
import patrickkeimel from '../assets/images/patrick-keimel-eventdrop.jpg';

class TestimonialSlider extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        name: this.props.name,
        position: this.props.position,
        review: this.props.review,
        image: this.props.image,
        reviews: [
            {
                name: 'Issa Saleh',
                position: 'Projektleiter',
                company: 'goldenleg Projects GmbH',
                review: 'Bester Webdesigner der Region! Immer erreichbar, zuverlässig und setzt vor allem präzise und genau seine Aufgaben um. Wir arbeiten jetzt schon seit fast 10 Jahren zusammen.',
                image: issasaleh
            },
            {
                name: 'Christoph Konitzer',
                position: 'Geschäftsführer',
                company: 'DREILAUT UG',
                review: 'Danke für die gute Zusammenarbeit!',
                image: christophkonitzer
            },
            {
                name: 'Julia Hüttmann',
                position: 'Geschäftsführerin',
                company: 'Julia Cosmetics',
                review: 'Ich bin mit der Arbeit von Herrn Ivanov immer sehr zufrieden. Er ist sehr zuverlässig, pünktlich und arbeitet schnell. Zudem ist das Preis Leistungsverhältnis kundenfreundlich. Ich würde ihn immer weiterempfehlen.',
                image: juliahuttmann
            },
            {
                name: 'Felix Michalski',
                position: 'Geschäftsführer',
                company: 'Ghostcreators',
                review: 'Sehr zuverlässig, keine langen Wartezeiten und immer auf dem neuesten Stand!',
                image: felixmichalski
            },
            {
                name: 'Björn Beglau',
                position: 'Geschäftsführer',
                company: 'Beglau Wärmepumpen GmbH',
                review: 'Super Arbeit und Betreuung!',
                image: bjornbeglau
            },
            {
                name: 'Stephan Nette',
                position: 'Geschäftsführer',
                company: 'Versichert Nette',
                review: 'Erik arbeitet sehr zuverlässig, arbeitet für einen absolut fairen Preis und versucht bis zum letzten Punkt alles für seinen Kunden umzusetzen. Ich habe Erik bereits weiterempfohlen und werde es auch zukünftig machen!',
                image: stephannette
            },
            {
                name: 'Juan Fladl',
                position: 'Geschäftsführer',
                company: 'Fladl Versicherungen',
                review: 'Im Hinblick auf den Wunsch einer eigenen Webseite bin ich bei Erik gelandet. Ich kann ihn nach erfolgreicher Umsetzung wärmstens empfehlen. Neben der Top-Umsetzung meiner Ideen, punktet Erik mit Schnelligkeit, Professionalität und weiterführenden Support. Klarer Fall für 5 Sterne!',
                image: juanfladl
            },
            {
                name: 'Patrick Keimel',
                position: 'Geschäftsführer',
                company: 'Eventdrop',
                review: 'Ich hatte das Vergnügen, mit Erik Ivanov zusammenzuarbeiten und kann nur Positives berichten. Die Zusammenarbeit war äußerst angenehm und schnell. Erik hat mir viele Lösungsvorschläge und sinnvolle Ideen präsentiert, die mir sehr weitergeholfen haben. Ich schätze besonders seine Professionalität und Expertise in seinem Fachgebiet. Er konnte meine Anliegen sehr schnell verstehen und hat umgehend passende Lösungen umsetzen. Alles in allem war es eine sehr positive Erfahrung und ich würde jederzeit wieder mit Erik zusammenarbeiten. Ich kann ihn ohne Vorbehalt empfehlen. Vielen Dank, Erik!',
                image: patrickkeimel
            }
        ]
    }
    render() { 
        return (
            <section className={cn(styles.testimonial)}>
                <div className="container">
                <Swiper
                spaceBetween={30}
                slidesPerView={1}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: true,
                }}
                className={cn(styles.testimonialContainer)}
                modules={[Autoplay]}
                >
                    {this.state.reviews.map((review) => (
                    <SwiperSlide>
                        <div className={cn(styles.testimonialWrapper)}>
                            <div className={cn(styles.testimonialReview)}>
                                {review.review}
                            </div>
                            <div className={cn(styles.testimonialAuthorWrapper)}>
                                <div className={cn(styles.testimonialAuthorImage)}>
                                    <img src={review.image} alt={review.name}/>
                                </div>
                                <div className={cn(styles.testimonialAuthor)}>
                                    {review.name}
                                    <span className={cn(styles.testimonialPosition)}>{review.position}</span>
                                    <span className={cn(styles.testimonialCompany)}>{review.company}</span>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    ))}
                </Swiper>
                </div>
            </section>
        );
    }
}
 
export default TestimonialSlider;