import React from 'react';
import { SwitchTransition, Transition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
import { TimelineLite, TweenMax, Power1, Power2, Power3 } from "gsap";

const TransitionComponent = ({ children }) => {
  const location = useLocation();
  return (
    <SwitchTransition>
      <Transition
        key={location.pathname}
        timeout={500}
        onEnter={(node) => {
            TweenMax.set(document.getElementById("content"), {y: 200});
            TweenMax.to(document.getElementById("headerStatus"), 0.4, {force3D:true, opacity:1, y: 0, delay:0.4, ease:Power3.easeOut});
            TweenMax.to(document.getElementById("projectLink"), 0.4, {force3D:true, opacity:1, y: 0, delay:0.4, ease:Power3.easeOut});
            TweenMax.to(document.getElementById("content"), 0.4, {force3D:true, opacity:1, y: 0, delay:0.45, ease:Power3.easeOut});
        }}
        onExit={(node) => {
            TweenMax.to(document.getElementById("headerStatus"), .6, {force3D: true, opacity: 0, y: -100, ease: Power2.easeInOut  });
            TweenMax.to(document.getElementById("projectLink"), .4, {force3D: true, opacity: 0, y: -30, ease: Power2.easeInOut  });
            TweenMax.to(document.getElementById("content"), 0.4, {force3D: true, opacity: 0, y: -150, delay: 0.2, ease: Power2.easeInOut  });
        }}
      >
        {children}
      </Transition>
    </SwitchTransition>
  );
};

export default TransitionComponent;
