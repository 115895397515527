import React from "react";
import { hydrate, render } from "react-dom";
import { Provider } from 'react-redux'
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './scss/main.scss';

import store from './redux/store'

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(<Provider store={store}><BrowserRouter><App /></BrowserRouter></Provider>, rootElement);
} else {
    render(<Provider store={store}><BrowserRouter><App /></BrowserRouter></Provider>, rootElement);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();