import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from '../scss/contactCTA.module.scss';

class ContactCTA extends Component {
    constructor(props) {
        super(props);
    }
    state = {  }
    render() { 
        return (
            <section className={cn(styles.contactCTA)}>
                <Link className={cn(styles.contactCTAButton)} to="/kontakt/">
                    <span>Jetzt kontaktieren</span>
                    <span>0385 48597862 oder zum Kontaktformular</span>
                </Link>
            </section>
        );
    }
}
 
export default ContactCTA;