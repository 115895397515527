import React, { Component } from 'react';
import cn from 'classnames';
import styles from '../scss/about.module.scss';

class UeberMich extends Component {
    constructor(props) {
        super(props);
    }
    state = {  }
    render() { 
        return (
            <section className={cn(styles.about)}>
                <div className="container">
                    <div className="headline">
                        <h2>Was uns<br/><span>besonders macht.</span></h2>
                    </div>
                    <div className="row justify-end align-end">
                        <div className="col">
                            <div className={cn(styles.teamleader)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="215" height="42.955" viewBox="0 0 215 42.955">
                                    <g id="TL-logo-RGB" transform="translate(-17 -17)">
                                        <path id="Pfad_275" data-name="Pfad 275" d="M120.908,45.353a9.39,9.39,0,0,1,1.492,5.868s-.046.806-.046,1.12H110.779a4.238,4.238,0,0,0,1.266,3.315,3.775,3.775,0,0,0,2.577,1.03,3.315,3.315,0,0,0,2.533-.806,3.106,3.106,0,0,0,.633-.985l3.165,1.836.09.09a5.681,5.681,0,0,1-.542.761,7.592,7.592,0,0,1-5.879,2.374,7.768,7.768,0,0,1-5.516-2.06,9.133,9.133,0,0,1-2.668-6.763,9.366,9.366,0,0,1,2.532-6.764,7.666,7.666,0,0,1,5.562-2.15A7.5,7.5,0,0,1,120.908,45.353Zm-10.265,4.076h7.552a4.493,4.493,0,0,0-1.04-2.956,4.034,4.034,0,0,0-5.607.314A4.246,4.246,0,0,0,110.643,49.429Z" fill="#1a1c20" fillRule="evenodd"/>
                                        <path id="Pfad_276" data-name="Pfad 276" d="M47.386,45.353a9.388,9.388,0,0,1,1.492,5.868,5.158,5.158,0,0,0,.045,1.12H37.257a4.237,4.237,0,0,0,1.266,3.315,3.774,3.774,0,0,0,2.577,1.03,3.313,3.313,0,0,0,2.532-.806,3.1,3.1,0,0,0,.633-.985l3.165,1.836.09.09a5.593,5.593,0,0,1-.543.761A7.591,7.591,0,0,1,41.1,59.955a7.768,7.768,0,0,1-5.516-2.06,9.132,9.132,0,0,1-2.668-6.763,9.366,9.366,0,0,1,2.532-6.764,7.663,7.663,0,0,1,5.562-2.15A7.493,7.493,0,0,1,47.386,45.353ZM37.167,49.429h7.506a4.473,4.473,0,0,0-.995-2.956,4.033,4.033,0,0,0-5.607.314A4.242,4.242,0,0,0,37.167,49.429Z" fill="#1a1c20" fillRule="evenodd"/>
                                        <path id="Pfad_277" data-name="Pfad 277" d="M175.3,45.353a9.39,9.39,0,0,1,1.492,5.868,5.186,5.186,0,0,0,.045,1.12H165.174a4.236,4.236,0,0,0,1.267,3.315,3.771,3.771,0,0,0,2.577,1.03,3.314,3.314,0,0,0,2.532-.806,3.106,3.106,0,0,0,.633-.985l3.165,1.836.091.09a5.6,5.6,0,0,1-.543.761,7.592,7.592,0,0,1-5.878,2.374,7.769,7.769,0,0,1-5.517-2.06,9.132,9.132,0,0,1-2.667-6.763,9.366,9.366,0,0,1,2.532-6.764,7.662,7.662,0,0,1,5.561-2.15A7.493,7.493,0,0,1,175.3,45.353Zm-10.264,4.076h7.551a4.265,4.265,0,0,0-1.04-2.956,4.034,4.034,0,0,0-5.607.314A4.24,4.24,0,0,0,165.039,49.429Z" fill="#1a1c20" fillRule="evenodd"/>
                                        <path id="Pfad_278" data-name="Pfad 278" d="M67.552,56.327v3a4.493,4.493,0,0,1-2.125.493,2.71,2.71,0,0,1-2.939-2.374,6.2,6.2,0,0,1-5.29,2.508c-4.16,0-6.285-2.508-6.285-5.285,0-3.225,2.623-5.062,5.923-5.42l5.11-.358v-1.03c0-1.568-.543-2.643-2.939-2.643-1.944,0-3.075.851-3.165,2.419H51.771c.226-3.763,3.346-5.465,7.235-5.465,2.984,0,5.562.9,6.556,3.449a8.776,8.776,0,0,1,.5,3.4V55.3c0,.761.226,1.075.95,1.075A3.818,3.818,0,0,0,67.552,56.327Zm-10.083.358c2.623,0,4.476-1.344,4.476-3.852v-.761l-4.431.313c-1.311.09-2.532.851-2.532,2.195A2.268,2.268,0,0,0,57.469,56.685Z" fill="#1a1c20" fillRule="evenodd"/>
                                        <path id="Pfad_279" data-name="Pfad 279" d="M94.863,48.13V59.552H90.7V49.071c0-2.06-.543-3.27-2.668-3.27-2.4,0-3.436,2.419-3.436,4.524v9.227h-4.16V49.071c0-2.06-.543-3.27-2.668-3.27-2.351,0-3.437,2.419-3.437,4.524v9.227h-4.16V42.621h3.482l.316,2.24a5.746,5.746,0,0,1,4.974-2.643,5.218,5.218,0,0,1,5.019,2.777,5.749,5.749,0,0,1,5.245-2.777C92.919,42.218,94.863,44.637,94.863,48.13Z" fill="#1a1c20"/>
                                        <path id="Pfad_280" data-name="Pfad 280" d="M140.622,56.327v3a3.584,3.584,0,0,1-2.17.358,2.711,2.711,0,0,1-2.94-2.374,6.2,6.2,0,0,1-5.29,2.508c-4.16,0-6.285-2.508-6.285-5.285,0-3.225,2.623-5.061,5.923-5.285l5.11-.358v-1.03c0-1.568-.543-2.643-2.939-2.643-1.945,0-3.075.851-3.165,2.419H124.8c.226-3.763,3.346-5.465,7.235-5.465,2.984,0,5.561.9,6.556,3.449a8.781,8.781,0,0,1,.5,3.4V55.3c0,.761.271,1.075.994,1.075A3.814,3.814,0,0,0,140.622,56.327Zm-10.083.358c2.622,0,4.476-1.344,4.476-3.852v-.761l-4.431.313c-1.311.09-2.532.851-2.532,2.195A2.267,2.267,0,0,0,130.539,56.685Z" fill="#1a1c20" fillRule="evenodd"/>
                                        <path id="Pfad_281" data-name="Pfad 281" d="M189.817,42.531v3.9a5.609,5.609,0,0,0-1.492-.134c-2.939,0-4.476,2.016-4.476,4.748v8.51h-4.205V42.666h3.572l.362,2.553c.813-1.971,2.893-2.777,4.928-2.777a11.744,11.744,0,0,1,1.2.078Z" fill="#1a1c20"/>
                                        <path id="Pfad_282" data-name="Pfad 282" d="M98.435,35.589l4.024-2.329.181-.09V54.491c0,.985.226,1.612,1.673,1.612a3.7,3.7,0,0,0,.473-.027,2.022,2.022,0,0,1,.251-.018v3.4l-.2.042a6,6,0,0,1-1.655.182c-2.849,0-4.748-1.3-4.748-4.524Z" fill="#1a1c20"/>
                                        <path id="Pfad_283" data-name="Pfad 283" d="M153.87,43.776c-.744-.869-2.351-1.513-4.521-1.513a6.961,6.961,0,0,0-4.251,1.344c-2.08,1.657-3.12,4.21-3.12,7.525,0,3.18,1,5.733,3.075,7.346a6.818,6.818,0,0,0,4.25,1.433,5.729,5.729,0,0,0,4.884-2.24l.271,1.837h3.617V33.17l-4.205,2.419Zm-3.753,12.73c3.3,0,3.979-3.046,3.979-5.465s-.633-5.375-3.979-5.42a3.394,3.394,0,0,0-2.8,1.344,6.521,6.521,0,0,0-1.085,4.076,6.735,6.735,0,0,0,1.085,4.121A3.393,3.393,0,0,0,150.117,56.506Z" fill="#1a1c20" fillRule="evenodd"/>
                                        <path id="Pfad_284" data-name="Pfad 284" d="M35.4,36.529v3.9H28.349V59.552h-4.3V40.426H17v-3.9Z" fill="#1a1c20"/>
                                        <path id="Pfad_285" data-name="Pfad 285" d="M205,52,194,35h11Z" fill="#00b2b2"/>
                                        <path id="Pfad_286" data-name="Pfad 286" d="M205,34H179l26-17Z" fill="#00b2b2"/>
                                        <path id="Pfad_287" data-name="Pfad 287" d="M206,17l15.914,24.595L206,52Z" fill="#00b2b2"/>
                                        <path id="Pfad_288" data-name="Pfad 288" d="M222.751,41.047,232,35H218.838Z" fill="#00b2b2"/>
                                    </g>
                                </svg>
                                <p>Minutengenaue Zeitabrechnung<br/>und ausführliche Protokollierung Ihrer Aufträge</p>
                            </div>
                        </div>
                        <div className="col">
                            <p>Eine WordPress Seite muss zuverlässig und ohne Komplikationen funktionieren und Ihren Kunden einen bestmöglichen Einblick über Ihr Unternehmen zu gewähren. Daher sollte Ihre Homepage optimal auf Ihre Kunden abgestimmt sein. In ausführlichen Beratungsgesprächen erarbeiten wir Ihnen eine Webseite, die optimal für Sie und Ihre Kunden zugeschnitten ist. Über Ihre Webseite haben Sie auch die Möglichkeit Neukunden zu generieren und das ganz ohne zusätzliche Arbeit.</p>    
                            <p>
                                <a href="https://cloud.teamleader.eu/erik-ivanov/bookings/u/erik/t/30-min%C3%BCtiger-online-termin-mit-erik-ivanov/" className="btn gold animated" target="_blank" ref="noopener noreferrer" title="Beratungsgespräch vereinbaren">
                                    Beratungsgespräch vereinbaren
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.768" height="15.537" viewBox="0 0 15.768 15.537">
                                        <path id="arrow-forward" d="M107.413,112l6.354,6.354-6.354,6.354m5.472-6.354H100" transform="translate(-99 -110.586)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                    </svg>
                                </a>
                                <a href="tel:+4938548597862" className="btn gold tel link" title="0385 48597862">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.325" height="17.328" viewBox="0 0 17.325 17.328">
                                        <path id="call_1_" data-name="call (1)" d="M45.98,49.338a7.288,7.288,0,0,1-3.4-1.16,22.131,22.131,0,0,1-5.346-4,20.287,20.287,0,0,1-4.015-5.344c-1.425-2.592-1.182-3.95-.911-4.531a3.616,3.616,0,0,1,1.418-1.521,6.819,6.819,0,0,1,1.108-.588l.107-.047a1.035,1.035,0,0,1,.849-.077,2.487,2.487,0,0,1,.807.619,13.41,13.41,0,0,1,2.018,2.995,2.984,2.984,0,0,1,.4,1.227,1.938,1.938,0,0,1-.493,1.153c-.051.069-.1.135-.15.2-.294.387-.359.5-.316.7a7.337,7.337,0,0,0,1.787,2.65,7.033,7.033,0,0,0,2.619,1.743c.208.044.322-.023.721-.328.057-.044.116-.089.178-.134a1.949,1.949,0,0,1,1.17-.524h0a2.8,2.8,0,0,1,1.232.432,14.776,14.776,0,0,1,2.984,2,2.486,2.486,0,0,1,.621.8,1.043,1.043,0,0,1-.077.851c-.014.032-.03.067-.047.106a6.826,6.826,0,0,1-.591,1.105,3.617,3.617,0,0,1-1.523,1.415A2.608,2.608,0,0,1,45.98,49.338Z" transform="translate(-32.098 -32.009)" fill="#dbac52"/>
                                    </svg>
                                    0385 48597862
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default UeberMich;