
import React, { useRef, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import Helmet from 'react-helmet';

import Error from './components/Error';
import Footer from './components/Footer';
import Header from './components/Header';
import Intro from './components/Intro';
import Blog from './components/Blog';
import BlogIntro from './components/BlogIntro';
import BlogContent from './components/BlogContent';
import ProjectIntro from './components/ProjectIntro';
import Clients from './components/Clients';
import ScrollIndicator from "./components/ScrollIndicator";
import Stats from "./components/Stats";
import ContactCTA from "./components/ContactCTA";
import Stats2 from "./components/Stats2";
import Konfigurator from "./components/Konfigurator";
import KonfiguratorAuswertung from "./components/KonfiguratorAuswertung";
import Projects from "./components/Projects";
import ProjectScreenshot from "./components/ProjectScreenshot";
import ProjectsList from "./components/ProjectsList";
import Imprint from "./components/Imprint";
import PrivacyPolicy from "./components/PrivacyPolicy";
import UeberMich from "./components/UeberMich";
import Contact from "./components/Contact";
import FAQ from "./components/FAQ";
import City from "./components/City";
import ScrollTop from "./components/ScrollTop";
import Service from "./components/Service";
import Steps from "./components/Steps";
import GoogleFontsScanner from "./components/GoogleFontsScanner";
import Testimonial from "./components/Testimonial";
import TestimonialSlider from "./components/TestimonialSlider";
import Welcome from "./components/Welcome";
import ProjektInfo from "./components/ProjektInfo";
import AirbeatDesktop from "./assets/images/airbeat-one-desktop.jpg";
import AirbeatMobil from "./assets/images/airbeat-one-mobil.jpg";
import ProjectsImages from "./components/ProjectsImages";
import WebdesignBlock from "./components/WebdesignBlock";
import ThankYouPage from "./components/ThankYouPage";

import Transition from "./components/Transition";

import airbeatImage from './assets/images/airbeat-one.webp';
import wiltsImage from './assets/images/wilts-keyser-project.webp';
import trendlineImage from './assets/images/trendline-traumhaus-background.webp';
import beglauImage from './assets/images/beglau-waermepumpen-projekt.webp';
import juliaImage from './assets/images/julia-cosmetics-background.webp';
import buckImage from './assets/images/svenbuck-projekt.webp';

import socialCard from './assets/images/social-card-default.jpg';

function App() {
  return (
    <>
      <Header/>
      <ScrollTop />
      <ScrollIndicator/>
      <main id="content">
        <Routes>
        <Route path="*" key="Error" element={<Transition><Error /></Transition>} />
          <Route exact path="/" key="Home" element={<Transition><Home /></Transition>} />
          <Route exact path="/ueber-mich" key="ÜberMich" element={<Transition><About /></Transition>} />
          <Route exact path="/vielen-dank" key="VielenDank" element={<Transition><ThankYou /></Transition>} />
          <Route exact path="/referenzen" key="Referenzen" element={<Transition><Portfolio /></Transition>} />
          <Route exact path="/referenzen/sven-buck" key="SvenBuck" element={<Transition><SvenBuck /></Transition>} />
          <Route exact path="/referenzen/airbeat-one" key="AirbeatOne" element={<Transition><AirbeatOne /></Transition>} />
          <Route exact path="/referenzen/julia-cosmetics" key="JuliaCosmetics" element={<Transition><JuliaCosmetics /></Transition>} />
          <Route exact path="/referenzen/beglau-waermepumpen" key="Beglau" element={<Transition><Beglau /></Transition>} />
          <Route exact path="/referenzen/wilts-keyser" key="WiltsKeyser" element={<Transition><WiltsKeyser /></Transition>} />
          <Route exact path="/referenzen/trendline-traumhaus" key="Trendline" element={<Transition><Trendline /></Transition>} />
          <Route exact path="/konfigurator" key="Konfigurator" element={<Transition><Configurator /></Transition>} />
          <Route exact path="/konfigurator/auswertung" key="KonfiguratorAuswertung" element={<Transition><ConfiguratorResult /></Transition>} />
          <Route exact path="/kontakt" key="Kontakt" element={<Transition><ContactSite /></Transition>} />
          <Route exact path="/leistungen" key="Leistungen" element={<Transition><ServiceSite /></Transition>} />
          <Route exact path="/webdesign-schwerin" key="WebdesignSchwerin" element={<Transition><WebdesignSchwerin /></Transition>} />
          <Route exact path="/webdesign-wismar" key="WebdesignWismar" element={<Transition><WebdesignWismar /></Transition>} />
          <Route exact path="/webdesign-luebeck" key="WebdesignLuebeck" element={<Transition><WebdesignLuebeck /></Transition>} />
          <Route exact path="/webdesign-hamburg" key="WebdesignHamburg" element={<Transition><WebdesignHamburg /></Transition>} />
          <Route exact path="/webdesign-rostock" key="WebdesignRostock" element={<Transition><WebdesignRostock /></Transition>} />
          <Route exact path="/webdesign-berlin" key="WebdesignRostock" element={<Transition><WebdesignBerlin /></Transition>} />
          <Route exact path="/impressum" key="Impressum" element={<Transition><Impressum /></Transition>} />
          <Route exact path="/datenschutz" key="Datenschutz" element={<Transition><Datenschutz /></Transition>} />

          <Route exact path="/blog" key="Blog" element={<Transition><BlogPage /></Transition>} />
          <Route exact path="/blog/:slug" key="Blog" element={<Transition><BlogSingle/></Transition>} />
        </Routes>
      </main>
    </>
  );
}

function BlogSingle(props) {
  return(
    <>
      <div>
        <BlogContent />
      </div>
    </>
  )
}

function ThankYou() {
  return(
    <>
    
      <Helmet>
        <title>Vielen Dank | Erik Ivanov</title>
        <meta name="description" content="Vielen Dank für Ihre Anfrage." />
        <meta name="robots" content="noindex, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/webdesign-schwerin/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Vielen Dank | Erik Ivanov" />
        <meta property="og:description" content="Vielen Dank für Ihre Anfrage." />
        <meta property="og:url" content="https://erik-ivanov.de/webdesign-schwerin/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2023-05-16T20:57:53+00:00" />
        <meta property="og:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Vielen Dank | Erik Ivanov" />
        <meta name="twitter:description" content="Vielen Dank für Ihre Anfrage." />
        <meta name="twitter:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
      </Helmet>
      <div>
        <ThankYouPage />
        <Footer/>
      </div>
    </>
  )
}

function BlogPage() {
  return(
    <>
      <div>
        <Intro ratingStars={false} googlePartner={false} title="Von der Idee<br/><span>zur Umsetzung</span><br/>Blog"/>
        <Blog/>
        <Footer/>
      </div>
    </>
  )
}

function WebdesignSchwerin() {
  return (
    <>
      <Helmet>
        <title>Webdesign Schwerin | Professionelle Webseiten</title>
        <meta name="description" content="Erhalten Sie ein professionelles Webdesign in Schwerin! Wir bieten Ihnen WordPress-Lösungen, eigene Programmierung und eine Vielzahl an hochwertigen Webseiten. Erfahren Sie mehr über unsere Dienstleistungen und finden Sie heraus, wie wir Ihnen dabei helfen können, Ihr Webdesign zu verbessern und Ihr Unternehmen zu stärken." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/webdesign-schwerin/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Webdesign Schwerin | Professionelle Webseiten" />
        <meta property="og:description" content="Erhalten Sie ein professionelles Webdesign in Schwerin! Wir bieten Ihnen WordPress-Lösungen, eigene Programmierung und eine Vielzahl an hochwertigen Webseiten. Erfahren Sie mehr über unsere Dienstleistungen und finden Sie heraus, wie wir Ihnen dabei helfen können, Ihr Webdesign zu verbessern und Ihr Unternehmen zu stärken." />
        <meta property="og:url" content="https://erik-ivanov.de/webdesign-schwerin/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2023-05-16T20:57:53+00:00" />
        <meta property="og:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Webdesign Schwerin | Professionelle Webseiten" />
        <meta name="twitter:description" content="Erhalten Sie ein professionelles Webdesign in Schwerin! Wir bieten Ihnen WordPress-Lösungen, eigene Programmierung und eine Vielzahl an hochwertigen Webseiten. Erfahren Sie mehr über unsere Dienstleistungen und finden Sie heraus, wie wir Ihnen dabei helfen können, Ihr Webdesign zu verbessern und Ihr Unternehmen zu stärken." />
        <meta name="twitter:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
      </Helmet>
      <Intro ratingStars={false} googlePartner={true} title="Professionelles<br/><span>Webdesign</span><br/>in Schwerin"/>
      <div>
        <City city="schwerin"/>
        <WebdesignBlock city="schwerin"/>
        <TestimonialSlider/>
        <Clients/>
        <FAQ/>
        <Projects/>
        <Clients/>
        <ContactCTA/>
        <Footer/>
      </div>
    </>
  )
}

function WebdesignWismar() {
  return (
    <>
      <Helmet>
        <title>Webdesign Wismar | Professionelle Webseiten</title>
        <meta name="description" content="Erhalten Sie ein professionelles Webdesign in Wismar! Wir bieten Ihnen WordPress-Lösungen, eigene Programmierung und eine Vielzahl an hochwertigen Webseiten. Erfahren Sie mehr über unsere Dienstleistungen und finden Sie heraus, wie wir Ihnen dabei helfen können, Ihr Webdesign zu verbessern und Ihr Unternehmen zu stärken." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/webdesign-wismar/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Webdesign Wismar | Professionelle Webseiten" />
        <meta property="og:description" content="Erhalten Sie ein professionelles Webdesign in Wismar! Wir bieten Ihnen WordPress-Lösungen, eigene Programmierung und eine Vielzahl an hochwertigen Webseiten. Erfahren Sie mehr über unsere Dienstleistungen und finden Sie heraus, wie wir Ihnen dabei helfen können, Ihr Webdesign zu verbessern und Ihr Unternehmen zu stärken." />
        <meta property="og:url" content="https://erik-ivanov.de/webdesign-wismar/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2023-05-16T20:57:53+00:00" />
        <meta property="og:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Webdesign Wismar | Professionelle Webseiten" />
        <meta name="twitter:description" content="Erhalten Sie ein professionelles Webdesign in Wismar! Wir bieten Ihnen WordPress-Lösungen, eigene Programmierung und eine Vielzahl an hochwertigen Webseiten. Erfahren Sie mehr über unsere Dienstleistungen und finden Sie heraus, wie wir Ihnen dabei helfen können, Ihr Webdesign zu verbessern und Ihr Unternehmen zu stärken." />
        <meta name="twitter:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
      </Helmet>
      <div>
        <Intro ratingStars={false} googlePartner={true} title="Qualitatives<br/><span>Webdesign</span><br/>in Wismar"/>
        <City city="wismar"/>
        <WebdesignBlock city="wismar"/>
        <TestimonialSlider/>
        <Clients/>
        <FAQ/>
        <Projects/>
        <Clients/>
        <ContactCTA/>
        <Footer/>
      </div>
    </>
  )
}

function WebdesignLuebeck() {
  return (
    <>
      <Helmet>
        <title>Webdesign Lübeck | Professionelle Webseiten</title>
        <meta name="description" content="Sichern Sie sich professionelles Webdesign in Lübeck! Unser Team bietet Ihnen maßgeschneiderte WordPress-Lösungen, individuelle Programmierung und eine breite Palette hochwertiger Webseiten." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/webdesign-luebeck/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Webdesign Lübeck | Professionelle Webseiten" />
        <meta property="og:description" content="Sichern Sie sich professionelles Webdesign in Lübeck! Unser Team bietet Ihnen maßgeschneiderte WordPress-Lösungen, individuelle Programmierung und eine breite Palette hochwertiger Webseiten." />
        <meta property="og:url" content="https://erik-ivanov.de/webdesign-luebeck/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2024-05-13T15:57:53+00:00" />
        <meta property="og:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Webdesign Lübeck | Professionelle Webseiten" />
        <meta name="twitter:description" content="Sichern Sie sich professionelles Webdesign in Lübeck! Unser Team bietet Ihnen maßgeschneiderte WordPress-Lösungen, individuelle Programmierung und eine breite Palette hochwertiger Webseiten." />
        <meta name="twitter:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
      </Helmet>
      <div>
        <Intro ratingStars={false} googlePartner={true} title="Exzellentes<br/><span>Webdesign</span><br/>in Lübeck"/>
        <City city="luebeck"/>
        <WebdesignBlock city="luebeck"/>
        <TestimonialSlider/>
        <Clients/>
        <FAQ/>
        <Projects/>
        <Clients/>
        <ContactCTA/>
        <Footer/>
      </div>
    </>
  )
}

function WebdesignHamburg() {
  return (
    <>
      <Helmet>
        <title>Webdesign Hamburg | Erfolgreiche Webseiten</title>
        <meta name="description" content="Erhalten Sie professionelle, benutzerfreundliche und einzigartige Webdesign-Lösungen aus Hamburg. Unser Team verfügt über umfangreiche Erfahrung im Bereich der WordPress-Programmierung und bietet Ihnen maßgeschneiderte Webseiten und Programmierungen. Wir beliefern Sie mit einem unübertroffenen Service und einer modernen Ansicht, die Ihren Kunden ein unvergessliches Erlebnis bietet." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/webdesign-hamburg/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Webdesign Hamburg | Erfolgreiche Webseiten" />
        <meta property="og:description" content="Erhalten Sie professionelle, benutzerfreundliche und einzigartige Webdesign-Lösungen aus Hamburg. Unser Team verfügt über umfangreiche Erfahrung im Bereich der WordPress-Programmierung und bietet Ihnen maßgeschneiderte Webseiten und Programmierungen. Wir beliefern Sie mit einem unübertroffenen Service und einer modernen Ansicht, die Ihren Kunden ein unvergessliches Erlebnis bietet." />
        <meta property="og:url" content="https://erik-ivanov.de/webdesign-hamburg/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2023-05-16T20:57:53+00:00" />
        <meta property="og:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Webdesign Hamburg | Erfolgreiche Webseiten" />
        <meta name="twitter:description" content="Erhalten Sie professionelle, benutzerfreundliche und einzigartige Webdesign-Lösungen aus Hamburg. Unser Team verfügt über umfangreiche Erfahrung im Bereich der WordPress-Programmierung und bietet Ihnen maßgeschneiderte Webseiten und Programmierungen. Wir beliefern Sie mit einem unübertroffenen Service und einer modernen Ansicht, die Ihren Kunden ein unvergessliches Erlebnis bietet." />
        <meta name="twitter:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
      </Helmet>
      <div>
        <Intro ratingStars={false} googlePartner={true} title="Professionelles<br/><span>Webdesign</span><br/>in Hamburg"/>
        <City city="hamburg"/>
        <WebdesignBlock city="hamburg"/>
        <TestimonialSlider/>
        <Clients/>
        <FAQ/>
        <Projects/>
        <Clients/>
        <Footer/>
      </div>
    </>
  )
}

function WebdesignRostock() {
  return (
    <>
      <Helmet>
        <title>Webdesign Rostock | Erfolgreiche Webseiten</title>
        <meta name="description" content="Erhalten Sie professionelle und ansprechende Webdesigns in Rostock! Wir bieten Ihnen WordPress-Lösungen, eigene Programmierung und einen kundenorientierten Service. Profitieren Sie von unseren Fachkenntnissen und lassen Sie sich von unserer Kompetenz überzeugen. Kontaktieren Sie uns noch heute und wir erstellen Ihnen eine maßgeschneiderte Webseite!" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/webdesign-rostock/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Webdesign Rostock | Erfolgreiche Webseiten" />
        <meta property="og:description" content="Erhalten Sie professionelle und ansprechende Webdesigns in Rostock! Wir bieten Ihnen WordPress-Lösungen, eigene Programmierung und einen kundenorientierten Service. Profitieren Sie von unseren Fachkenntnissen und lassen Sie sich von unserer Kompetenz überzeugen. Kontaktieren Sie uns noch heute und wir erstellen Ihnen eine maßgeschneiderte Webseite!" />
        <meta property="og:url" content="https://erik-ivanov.de/webdesign-rostock/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2023-05-16T20:57:53+00:00" />
        <meta property="og:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Webdesign Rostock | Erfolgreiche Webseiten" />
        <meta name="twitter:description" content="Erhalten Sie professionelle und ansprechende Webdesigns in Rostock! Wir bieten Ihnen WordPress-Lösungen, eigene Programmierung und einen kundenorientierten Service. Profitieren Sie von unseren Fachkenntnissen und lassen Sie sich von unserer Kompetenz überzeugen. Kontaktieren Sie uns noch heute und wir erstellen Ihnen eine maßgeschneiderte Webseite!" />
        <meta name="twitter:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
      </Helmet>
      <div>
        <Intro ratingStars={false} googlePartner={true} title="Professionelles<br/><span>Webdesign</span><br/>in Rostock"/>
        <City city="rostock"/>
        <WebdesignBlock city="rostock"/>
        <TestimonialSlider/>
        <Clients/>
        <FAQ/>
        <Projects/>
        <Clients/>
        <Footer/>
      </div>
    </>
  )
}

function WebdesignBerlin() {
  return (
    <>
      <Helmet>
        <title>Webdesign Berlin | Erfolgreiche Webseiten</title>
        <meta name="description" content="Gestalten Sie Ihr Webprojekt mit professionellem Webdesign und Programmierung aus Berlin. Wir bieten Ihnen modernste WordPress-Lösungen und eigene Programmierung, um Ihre Webseite zu einem einzigartigen Erlebnis zu machen. Mit unserem Fachwissen und Know-how helfen wir Ihnen, Ihr Webprojekt zum Erfolg zu führen. Kontaktieren Sie uns jetzt und machen Sie Ihre Webseite zu einem Top-Ergebnis." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/webdesign-berlin/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Webdesign Berlin | Erfolgreiche Webseiten" />
        <meta property="og:description" content="Gestalten Sie Ihr Webprojekt mit professionellem Webdesign und Programmierung aus Berlin. Wir bieten Ihnen modernste WordPress-Lösungen und eigene Programmierung, um Ihre Webseite zu einem einzigartigen Erlebnis zu machen. Mit unserem Fachwissen und Know-how helfen wir Ihnen, Ihr Webprojekt zum Erfolg zu führen. Kontaktieren Sie uns jetzt und machen Sie Ihre Webseite zu einem Top-Ergebnis." />
        <meta property="og:url" content="https://erik-ivanov.de/webdesign-berlin/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2023-05-16T20:57:53+00:00" />
        <meta property="og:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Webdesign Berlin | Erfolgreiche Webseiten" />
        <meta name="twitter:description" content="Gestalten Sie Ihr Webprojekt mit professionellem Webdesign und Programmierung aus Berlin. Wir bieten Ihnen modernste WordPress-Lösungen und eigene Programmierung, um Ihre Webseite zu einem einzigartigen Erlebnis zu machen. Mit unserem Fachwissen und Know-how helfen wir Ihnen, Ihr Webprojekt zum Erfolg zu führen. Kontaktieren Sie uns jetzt und machen Sie Ihre Webseite zu einem Top-Ergebnis." />
        <meta name="twitter:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
      </Helmet>
      <div>
        <Intro ratingStars={false} googlePartner={true} title="Professionelles<br/><span>Webdesign</span><br/>in Berlin"/>
        <City city="berlin"/>
        <WebdesignBlock city="berlin"/>
        <TestimonialSlider/>
        <Clients/>
        <FAQ/>
        <Projects/>
        <Clients/>
        <Footer/>
      </div>
    </>
  )
}

function ServiceSite() {
  return (
    <>
      <Helmet>
        <title>Leistungen | Erik Ivanov</title>
        <meta name="description" content="Gestalten Sie Ihr Webprojekt mit professionellem Webdesign und Programmierung aus Berlin. Wir bieten Ihnen modernste WordPress-Lösungen und eigene Programmierung, um Ihre Webseite zu einem einzigartigen Erlebnis zu machen. Mit unserem Fachwissen und Know-how helfen wir Ihnen, Ihr Webprojekt zum Erfolg zu führen. Kontaktieren Sie uns jetzt und machen Sie Ihre Webseite zu einem Top-Ergebnis." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/leistungen/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Leistungen | Erik Ivanov" />
        <meta property="og:description" content="Gestalten Sie Ihr Webprojekt mit professionellem Webdesign und Programmierung aus Berlin. Wir bieten Ihnen modernste WordPress-Lösungen und eigene Programmierung, um Ihre Webseite zu einem einzigartigen Erlebnis zu machen. Mit unserem Fachwissen und Know-how helfen wir Ihnen, Ihr Webprojekt zum Erfolg zu führen. Kontaktieren Sie uns jetzt und machen Sie Ihre Webseite zu einem Top-Ergebnis." />
        <meta property="og:url" content="https://erik-ivanov.de/leistungen/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2023-05-16T20:57:53+00:00" />
        <meta property="og:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Leistungen | Erik Ivanov" />
        <meta name="twitter:description" content="Gestalten Sie Ihr Webprojekt mit professionellem Webdesign und Programmierung aus Berlin. Wir bieten Ihnen modernste WordPress-Lösungen und eigene Programmierung, um Ihre Webseite zu einem einzigartigen Erlebnis zu machen. Mit unserem Fachwissen und Know-how helfen wir Ihnen, Ihr Webprojekt zum Erfolg zu führen. Kontaktieren Sie uns jetzt und machen Sie Ihre Webseite zu einem Top-Ergebnis." />
        <meta name="twitter:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
      </Helmet>
      <div>
        <Service/>
        <Footer/>
      </div>
    </>
  )
}

function ContactSite() {
  return (
    <div>
      <Helmet>
        <title>Kontakt | Erik Ivanov</title>
        <meta name="description" content="Sie benötigen Webdesign für Ihr Unternehmen? Kein Problem! Kontaktieren Sie uns jetzt unverbindlich für ein Angebot." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/kontakt/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Kontakt | Erik Ivanov" />
        <meta property="og:description" content="Sie benötigen Webdesign für Ihr Unternehmen? Kein Problem! Kontaktieren Sie uns jetzt unverbindlich für ein Angebot." />
        <meta property="og:url" content="https://erik-ivanov.de/kontakt/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2023-05-16T20:57:53+00:00" />
        <meta property="og:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Kontakt | Erik Ivanov" />
        <meta name="twitter:description" content="Sie benötigen Webdesign für Ihr Unternehmen? Kein Problem! Kontaktieren Sie uns jetzt unverbindlich für ein Angebot." />
        <meta name="twitter:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
      </Helmet>
      <Contact/>
      <Footer/>
    </div>
  )
}

function Portfolio() {
  return (
    <>
      <Helmet>
        <title>Portfolio | Erik Ivanov</title>
        <meta name="description" content="Überzeugen Sie sich von unseren Arbeiten. Stärken Sie Ihre Umsätze mit einer individuellen Webseite." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/referenzen/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Portfolio | Erik Ivanov" />
        <meta property="og:description" content="Überzeugen Sie sich von unseren Arbeiten. Stärken Sie Ihre Umsätze mit einer individuellen Webseite." />
        <meta property="og:url" content="https://erik-ivanov.de/referenzen/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2023-05-16T20:57:53+00:00" />
        <meta property="og:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Portfolio | Erik Ivanov" />
        <meta name="twitter:description" content="Überzeugen Sie sich von unseren Arbeiten. Stärken Sie Ihre Umsätze mit einer individuellen Webseite." />
        <meta name="twitter:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
      </Helmet>
      <Intro ratingStars={false} googlePartner={true} title="Professionell.<br/><span>Modern.</span><br/>Zufrieden."/>
      <div>
        <ProjectsList/>
        <Footer/>
      </div>
    </>
  )
}

function SvenBuck() {
  return (
    <>
      <Helmet>
        <title>Sven Buck | Erik Ivanov</title>
        <meta name="description" content="Webdesign in Schwerin von Erik Ivanov. Erreichen Sie mehr Sichtbarkeit und Erfolg im Internet. Jetzt kostenfreies Angebot sichern!" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/referenzen/sven-buck/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Sven Buck | Erik Ivanov" />
        <meta property="og:description" content="Webdesign in Schwerin von Erik Ivanov. Erreichen Sie mehr Sichtbarkeit und Erfolg im Internet. Jetzt kostenfreies Angebot sichern!" />
        <meta property="og:url" content="https://erik-ivanov.de/referenzen/sven-buck/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2023-05-16T20:57:53+00:00" />
        <meta property="og:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Sven Buck | Erik Ivanov" />
        <meta name="twitter:description" content="Webdesign in Schwerin von Erik Ivanov. Erreichen Sie mehr Sichtbarkeit und Erfolg im Internet. Jetzt kostenfreies Angebot sichern!" />
        <meta name="twitter:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
      </Helmet>
      <ProjectIntro image={buckImage} title="Sven Buck<br/><span>Architekten</span>"/>
      <div>
        <ProjektInfo logo="svenbuck" headline="Sven Buck Architekten" text="Sven Buck Freischaffende Architekten sind erfahrene und kreative Architekten, die individuelle Lösungen für Bauvorhaben aller Art entwickeln. Mit einem Schwerpunkt auf nachhaltigem und innovativem Design, bieten wir maßgeschneiderte Architekturkonzepte für private und gewerbliche Kunden. Unser Team aus Architekten und Designern arbeitet eng mit unseren Kunden zusammen, um ihre Visionen zu realisieren. Ob Neubau, Umbau oder Sanierung - wir bieten umfassende Dienstleistungen von der Planung bis zur Umsetzung. Kontaktieren Sie uns für professionelle Beratung und erstklassige Architekturdienstleistungen für Ihr Bauvorhaben." companyURL="https://musiceggert.de/"/>
        <Steps headline="Sven Buck" problem='Die ursprüngliche Webseite für das Architektenbüro "Sven Buck Freischaffende Architekten" war unübersichtlich & veraltet. Ziele im Bereich SEO konnten nicht erfolgreich umgesetzt werden.' solution="Eine moderne Webseite auf dem aktuellen Stand unter Beachtung aktuellster Techniken & SEO-Optimierungen auf Basis eines CMS-Systems." result="Eine performante und responsive Webseite auf Basis von Next.js & WordPress. Eine übersichtliche Menüführung sowie die Möglichkeit Inhalte jederzeit anzupassen."/>
        <Testimonial image="placeholder" name="Sven Buck" position="Geschäftsführer" review="Erik Ivanov hat eine herausragende Arbeit geleistet, indem er eine Webseite für unser Architektenbüro erstellt hat. Die Seite beeindruckt nicht nur durch ihre Ästhetik, sondern auch durch ihre Benutzerfreundlichkeit. Die Navigation ist nahtlos, und die Seiten laden schnell, was die Nutzererfahrung erheblich verbessert."/>
        <ProjectsImages project="svenbuck" url="svenbuck.de"/>
        <Projects/>
        <Footer/>
      </div>
    </>
  )
}

function AirbeatOne() {
  return (
    <>
      <Helmet>
        <title>Airbeat One | Erik Ivanov</title>
        <meta name="description" content="Webdesign in Schwerin von Erik Ivanov. Erreichen Sie mehr Sichtbarkeit und Erfolg im Internet. Jetzt kostenfreies Angebot sichern!" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/referenzen/airbeat-one/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Airbeat One | Erik Ivanov" />
        <meta property="og:description" content="Webdesign in Schwerin von Erik Ivanov. Erreichen Sie mehr Sichtbarkeit und Erfolg im Internet. Jetzt kostenfreies Angebot sichern!" />
        <meta property="og:url" content="https://erik-ivanov.de/referenzen/airbeat-one/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2023-05-16T20:57:53+00:00" />
        <meta property="og:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Airbeat One | Erik Ivanov" />
        <meta name="twitter:description" content="Webdesign in Schwerin von Erik Ivanov. Erreichen Sie mehr Sichtbarkeit und Erfolg im Internet. Jetzt kostenfreies Angebot sichern!" />
        <meta name="twitter:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
      </Helmet>
      <ProjectIntro image={airbeatImage} title="Airbeat One<br/><span>Festival</span>"/>
      <div>
        <ProjektInfo logo="musiceggert" headline="Music Eggert" text="Music Eggert ist Ihr professioneller Partner, wenn es um die Durchführung von Events, Verleih von Ton-, Licht- und Bühnentechnik geht. Von der Planung einer kleinen Feier bis zur Großveranstaltung sind Sie mit Music Eggert immer an der richtigen Seite. Mit zahlreichen Events hat sich Music Eggert über mehrere Jahre einen sehr positiven Eindruck in der Eventbranche verdient. Zu den bekanntesten Events gehört nicht nur das Airbeat One Dance Festival, sondern auch zahlreiche Nebenevents, wie das Indian Spirit Festival oder auch die Snowbeat in Wittenburg." companyURL="https://musiceggert.de/"/>
        <Steps headline="Airbeat One Festival" problem="Für die Veranstaltung Airbeat One sollte ein Gestaltungskonzept konzipiert und technisch umgesetzt werden. Wichtige Themen, wie Mehrsprachigkeit und eine benutzerfreundliche Webseite waren Kernprobleme der bestehenden Webseite." solution="Eine vollständig überarbeitete Webseite bietet den Gästen des Airbeat One Festivals eine übersichtliche und informative Plattform rund um das Festival. Neuste Technolgien und ein einzigartiges Gestaltungskonzept wurden auf Basis eines CMS Systems umgesetzt." result="Eine performante und responsive Webseite, die besonders auf Nutzer mit mobilen Endgeräten ausgerichtet ist. Eine logische und übersichtliche Menüführung sorgt für orientierte Nutzer und deutlich weniger Support-Aufwand."/>
        <Testimonial image="placeholder" name="Sebastian Eggert" position="Geschäftsführer" review="Die Zusammenarbeit mit Erik war äußerst reibungslos. Er hat unseren Input ernst genommen und seine eigenen kreativen Ideen eingebracht, um eine ansprechende und benutzerfreundliche Webseite zu entwickeln. Die Webseite, die er für uns entwickelt hat, spiegelt unsere Werte und unsere Professionalität wider. Er hat unsere Corporate Identity berücksichtigt und ein Design geschaffen, das sowohl ästhetisch ansprechend als auch funktional ist."/>
        <ProjectsImages project="airbeat" url="airbeat-one.de"/>
        <Projects/>
        <Footer/>
      </div>
    </>
  )
}

function JuliaCosmetics() {
  return (
      <>
      <Helmet>
        <title>Julia Cosmetics | Erik Ivanov</title>
        <meta name="description" content="Webdesign in Schwerin von Erik Ivanov. Erreichen Sie mehr Sichtbarkeit und Erfolg im Internet. Jetzt kostenfreies Angebot sichern!" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/referenzen/julia-cosmetics/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Julia Cosmetics | Erik Ivanov" />
        <meta property="og:description" content="Webdesign in Schwerin von Erik Ivanov. Erreichen Sie mehr Sichtbarkeit und Erfolg im Internet. Jetzt kostenfreies Angebot sichern!" />
        <meta property="og:url" content="https://erik-ivanov.de/referenzen/julia-cosmetics/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2023-05-16T20:57:53+00:00" />
        <meta property="og:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Julia Cosmetics | Erik Ivanov" />
        <meta name="twitter:description" content="Webdesign in Schwerin von Erik Ivanov. Erreichen Sie mehr Sichtbarkeit und Erfolg im Internet. Jetzt kostenfreies Angebot sichern!" />
        <meta name="twitter:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
      </Helmet>
      <ProjectIntro image={juliaImage} title="Julia<br/><span>Cosmetics</span>"/>
      <div>
        <ProjektInfo logo="julia" headline="Julia Cosmetics" url="https://julia-cosmetics.de/" text="Julia Hüttmann ist zertifizierte Pigmentiererin aus Kiel in Schleswig-Holsein. In Ihrem Studio berät Sie Ihre Kundinnnen zum Thema Schönheit und mögliche Behandlungen. Frau Hüttmann bietet Permanent Make-Up sowie Microblading an."/>
        <Steps headline="Julia Cosmetics" problem="Julia Hüttmann benötigte eine Webseite für Ihr Kosmetikstudio Julia Cosmetics, um Kunden einen ersten Einblick über Ihre Arbeit, sowie erste Einblicke in das Kosmetikstudio zu bieten. Mit der neuen Webseite sollten ebenfalls Neukunden geworben werden." solution="Mit einer individualisierten Webseite bietet Julia Cosmetics neben einer Preisübersicht auch eine Beschreibung über die angebotenen Dienstleistungen. Kunden können nun ganz einfach das bereitgestellte Kontaktformular nutzen, um eine Terminanfrage zu senden." result="Eine performante Webseite, optimiert für Mobilgeräte mit besonderem Fokus auf logische Menüführung und Beantwortung von allgemeinen Fragen. Durch unsere professionelle Umsetzung erreicht Julia Cosmetics Platz 1 in den Google-Suchergebnissen."/>
        <Testimonial image="julia" name="Julia Hüttmann" position="Geschäftsführerin" review="Ich bin mit der Arbeit von Herrn Ivanov immer sehr zufrieden. Er ist sehr zuverlässig, pünktlich und arbeitet schnell. Zudem ist das Preis Leistungsverhältnis kundenfreundlich. Ich würde ihn immer weiterempfehlen."/>
        <ProjectsImages project="julia" url="julia-cosmetics.de"/>
        <Projects/>
        <Footer/>
      </div>
    </>
  )
}

function WiltsKeyser() {
  return (
    <>
      <Helmet>
        <title>Wilts & Keyser | Erik Ivanov</title>
        <meta name="description" content="Webdesign in Schwerin von Erik Ivanov. Erreichen Sie mehr Sichtbarkeit und Erfolg im Internet. Jetzt kostenfreies Angebot sichern!" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/referenzen/wilts-keyser/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Wilts & Keyser | Erik Ivanov" />
        <meta property="og:description" content="Webdesign in Schwerin von Erik Ivanov. Erreichen Sie mehr Sichtbarkeit und Erfolg im Internet. Jetzt kostenfreies Angebot sichern!" />
        <meta property="og:url" content="https://erik-ivanov.de/referenzen/wilts-keyser/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2023-05-16T20:57:53+00:00" />
        <meta property="og:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Wilts & Keyser | Erik Ivanov" />
        <meta name="twitter:description" content="Webdesign in Schwerin von Erik Ivanov. Erreichen Sie mehr Sichtbarkeit und Erfolg im Internet. Jetzt kostenfreies Angebot sichern!" />
        <meta name="twitter:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
      </Helmet>
      <ProjectIntro image={wiltsImage} title="Wilts Keyser<br/><span>Rechtsanwälte</span>"/>
      <div>
        <ProjektInfo logo="wilts" headline="Wilts Keyser" url="https://wilts-keyser.de/" text="Wilts Keyser ist eine auf wirtschafts- und immobilienrechtliche Beratung und Prozessführung spezialisierte Kanzlei mit Standorten in Hamburg, Schwerin und Berlin"/>
        <Steps headline="Unternehmenswebseite" problem="Für die technische Umsetzung der Webseite wilts-keyser.de wurde ein passender Webentwickler gesucht. Ein Screendesign war bereits vorhanden und sollte mit einem modernen und einfachen CMS-System umgesetzt werden. Die Auffindbarkeit der Standorte Schwerin, Hamburg und Berlin lag dabei besonders im Fokus." solution="Die technische Umsetzung erfolgte anhand des CMS-Systems WordPress welches sich ideal für kleine und mittlere Projekte eignet. Die Webseite wurde individuell entwickelt ohne Einsatz von WordPress Themes. Für die Präsentation der einzelnen Kanzleistandorte wurden Google My Business Einträge erstellt und mit Inhalten gefüllt." result="Das Ergebnis ist eine moderne & suchmaschinenoptimierte Webseite auf Basis von WordPress. Die Webseite ist für Geräte jeglicher Art optimiert, sodass Benutzer sowohl mit Smartphones, als auch mit Desktop Computern immer das gleiche Nutzererlebnis haben."/>
        <Testimonial image="wilts" name="Felix Keyser" position="Geschäftsführerin" review="Die Zusammenarbeit mit Erik war äußerst reibungslos. Er war stets erreichbar, um auf unsere Fragen und Anliegen einzugehen. Unsere neue Webseite funktioniert auf verschiedenen Geräten und Browsern reibungslos. Insgesamt bin ich äußerst zufrieden mit der Leistung von Erik Ivanov als Webdesigner für unsere Rechtsanwaltskanzlei."/>
        <ProjectsImages project="wilts" url="wilts-keyser.de"/>
        <Projects/>
        <Footer/>
      </div>
    </>
  )
}

function Beglau() {
  return (
    <>
      <Helmet>
        <title>Beglau Wärmepumpen | Erik Ivanov</title>
        <meta name="description" content="Webdesign in Schwerin von Erik Ivanov. Erreichen Sie mehr Sichtbarkeit und Erfolg im Internet. Jetzt kostenfreies Angebot sichern!" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/referenzen/beglau-waermepumpen/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Beglau Wärmepumpen | Erik Ivanov" />
        <meta property="og:description" content="Webdesign in Schwerin von Erik Ivanov. Erreichen Sie mehr Sichtbarkeit und Erfolg im Internet. Jetzt kostenfreies Angebot sichern!" />
        <meta property="og:url" content="https://erik-ivanov.de/referenzen/beglau-waermepumpen/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2023-05-16T20:57:53+00:00" />
        <meta property="og:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Beglau Wärmepumpen | Erik Ivanov" />
        <meta name="twitter:description" content="Webdesign in Schwerin von Erik Ivanov. Erreichen Sie mehr Sichtbarkeit und Erfolg im Internet. Jetzt kostenfreies Angebot sichern!" />
        <meta name="twitter:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
      </Helmet>
      <ProjectIntro image={beglauImage} title="Beglau<br/><span>Wärmepumpen</span>"/>
      <div>
        <ProjektInfo logo="beglau" headline="Beglau Wärmepumpen GmbH" url="https://beglau.de/" text="Das Unternehmen Beglau Wärmepumpen ist ein Familienunternehmen seit 1985. Es steht seitdem für den Einklang von wohliger Wärme, warmem Wassers und erneuerbaren Energien. Zufriedene Kunden, mehrfache Technologie- und Innovationspreise sowie internationale Aufträge motivieren das Unternehmen jeden Tag aufs Neue, den Weg genauso engagiert fortzusetzen. Das Team um Dipl.-Ing. Wolfgang Beglau wird seit 2016 von seinen Söhnen Reik und Björn unterstützt."/>
        <Steps headline="Smart Home Steuerung" problem="Die Steuerzentrale für die innovativen Wärmepumpen der Firma Beglau Wärmepumpen GmbH war bereits veraltet. Das Interface zur Steuerung der Beglau Produkte war nicht benutzerfreundlich und schwer verständlich." solution="Durch zahlreiche Analyseberichte zur Nutzung der Steuerzentrale wurde ein einzigartiges Gestaltungskonzept entwickelt. Durch Nutzung des Frameworks React konnten wir durch eine Limitierung des Speicherchips (1MB) eine wunderschöne Oberfläche für den Endkunden bereitstellen." result="Ein vollständig überarbeitetes Interface bietet den Kunden auf Basis des Frameworks React, eine intuitive Benutzerführung und logische Anordnung der Einstellungsmöglichkeiten. Sowohl auf mobilen Endgeräten, als auch Desktop-Computern bietet die Steuerung in allen Szenarien die passende Schnittstelle."/>
        <Testimonial image="beglau" name="Björn Beglau" position="Projektleiter" review="Super Arbeit und Betreuung!"/>
        <ProjectsImages project="beglau" url="beglau.de"/>
        <Projects/>
        <Footer/>
      </div>
    </>
  )
}

function Trendline() {
  return (
    <>
      <Helmet>
        <title>Trendline das Haus | Erik Ivanov</title>
        <meta name="description" content="Webdesign in Schwerin von Erik Ivanov. Erreichen Sie mehr Sichtbarkeit und Erfolg im Internet. Jetzt kostenfreies Angebot sichern!" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/referenzen/trendline-traumhaus/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Trendline das Haus | Erik Ivanov" />
        <meta property="og:description" content="Webdesign in Schwerin von Erik Ivanov. Erreichen Sie mehr Sichtbarkeit und Erfolg im Internet. Jetzt kostenfreies Angebot sichern!" />
        <meta property="og:url" content="https://erik-ivanov.de/referenzen/trendline-traumhaus/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2023-05-16T20:57:53+00:00" />
        <meta property="og:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Trendline das Haus | Erik Ivanov" />
        <meta name="twitter:description" content="Webdesign in Schwerin von Erik Ivanov. Erreichen Sie mehr Sichtbarkeit und Erfolg im Internet. Jetzt kostenfreies Angebot sichern!" />
        <meta name="twitter:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
      </Helmet>
      <ProjectIntro image={trendlineImage} title="Trendline<br/><span>Traumhaus</span>"/>
      <div>
        <ProjektInfo logo="trendline" url="https://trendline-traumhaus.de" headline="Trendline Das Traumhaus" text="Trendline Traumhaus Schwerin ist Ihr Experte für moderne und individuelle Traumhäuser in Schwerin und Umgebung. Als erfahrener Anbieter von schlüsselfertigen Häusern bieten wir maßgeschneiderte Lösungen für jeden Wohnstil und jeden Bedarf. Mit unserer innovativen Architektur, hochwertigen Materialien und energieeffizienten Technologien schaffen wir nachhaltige und zukunftsfähige Wohnräume. Unser professionelles Team begleitet Sie von der Planung bis zur schlüsselfertigen Übergabe Ihres Traumhauses. Verwirklichen Sie Ihre Wohnträume mit Trendline Traumhaus Schwerin - kontaktieren Sie uns für eine unverbindliche Beratung und individuelle Angebote."/>
        <Steps headline="Trendline Traumhaus Webseite" problem="Die Firma Trendline – das Traumhaus GmbH & Co. KG benötigte eine überarbeitete Homepage für ihr Unternehmen. Die ursprüngliche Webseite bietet den Besuchern wenig Informationen zu Projekten & angebotenen Leistungen." solution="Eine neukonzipierte Webseite bietet den Besuchern mehr Informationen zum Unternehmen Trendline Traumhaus. Für Interessenten gibt es die Möglichkeit abgeschlossene Projekte anzusehen und mit einem integrierten Kontaktformular eine Anfrage zu schicken." result="Eine moderne und frische Webseite, "/>
        <Testimonial image="trendline" name="Frank Kleinsorg" position="Geschäftsführer" review="Von Anfang an war ich beeindruckt von der Professionalität und dem Engagement, die Erik Ivanov in seine Arbeit gesteckt hat. Seine Professionalität, sein technisches Wissen und sein Engagement haben dazu beigetragen, eine qualitativ hochwertige Webseite zu erstellen, die unsere Anforderungen erfüllt. Ich kann Erik Ivanov uneingeschränkt als Webdesigner empfehlen und würde ihn bei zukünftigen Projekten gerne wieder engagieren."/>
        <ProjectsImages project="trendline" url="trendline-traumhaus.de"/>
        <Projects/>
        <Footer/>
      </div>
    </>
  )
}

function Datenschutz() {
  return (
    <>
      <Helmet>
        <title>Datenschutz | Erik Ivanov</title>
        <meta name="description" content="Digitalagentur für Webdesign, Webentwicklung und Online Marketing. ✓ zuverlässig, günstig, Zielgruppen optimiert" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/datenschutz/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Datenschutz | Erik Ivanov" />
        <meta property="og:description" content="Digitalagentur für Webdesign, Webentwicklung und Online Marketing. ✓ zuverlässig, günstig, Zielgruppen optimiert" />
        <meta property="og:url" content="https://erik-ivanov.de/datenschutz/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2023-05-16T20:57:53+00:00" />
        <meta property="og:image" content={socialCard} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Datenschutz | Erik Ivanov" />
        <meta name="twitter:description" content="Digitalagentur für Webdesign, Webentwicklung und Online Marketing. ✓ zuverlässig, günstig, Zielgruppen optimiert" />
        <meta name="twitter:image" content={socialCard} />
      </Helmet>
      <div>
        <PrivacyPolicy/>
        <Footer/>
      </div>
    </>
  )
}

function Impressum() {
  return (
    <>
      <Helmet>
        <title>Impressum | Erik Ivanov</title>
        <meta name="description" content="Digitalagentur für Webdesign, Webentwicklung und Online Marketing. ✓ zuverlässig, günstig, Zielgruppen optimiert" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/impressum/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Impressum | Erik Ivanov" />
        <meta property="og:description" content="Digitalagentur für Webdesign, Webentwicklung und Online Marketing. ✓ zuverlässig, günstig, Zielgruppen optimiert" />
        <meta property="og:url" content="https://erik-ivanov.de/impressum/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2023-05-16T20:57:53+00:00" />
        <meta property="og:image" content={socialCard} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Impressum | Erik Ivanov" />
        <meta name="twitter:description" content="Digitalagentur für Webdesign, Webentwicklung und Online Marketing. ✓ zuverlässig, günstig, Zielgruppen optimiert" />
        <meta name="twitter:image" content={socialCard} />
      </Helmet>
      <div>
        <Imprint/>
        <Footer/>
      </div>
    </>
  )
}

function ConfiguratorResult() {
  return (
    <>
      <Helmet>
        <title>Erik Ivanov – Konfigurieren Sie Ihre Webseite</title>
        <meta name="description" content="Digitalagentur für Webdesign, Webentwicklung und Online Marketing. ✓ zuverlässig, günstig, Zielgruppen optimiert" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/konfigurator/auswertung/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Erik Ivanov – Konfigurieren Sie Ihre Webseite" />
        <meta property="og:description" content="Digitalagentur für Webdesign, Webentwicklung und Online Marketing. ✓ zuverlässig, günstig, Zielgruppen optimiert" />
        <meta property="og:url" content="https://erik-ivanov.de/konfigurator/auswertung/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2023-05-16T20:57:53+00:00" />
        <meta property="og:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Erik Ivanov – Konfigurieren Sie Ihre Webseite" />
        <meta name="twitter:description" content="Digitalagentur für Webdesign, Webentwicklung und Online Marketing. ✓ zuverlässig, günstig, Zielgruppen optimiert" />
        <meta name="twitter:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
      </Helmet>
      <div>
        <KonfiguratorAuswertung/>
        <Footer/>
      </div>
    </>
  )
}

function Configurator() {
  return (
    <>
      <Helmet>
        <title>Erik Ivanov – Konfigurieren Sie Ihre Webseite</title>
        <meta name="description" content="Digitalagentur für Webdesign, Webentwicklung und Online Marketing. ✓ zuverlässig, günstig, Zielgruppen optimiert" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/konfigurator/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Erik Ivanov – Konfigurieren Sie Ihre Webseite" />
        <meta property="og:description" content="Digitalagentur für Webdesign, Webentwicklung und Online Marketing. ✓ zuverlässig, günstig, Zielgruppen optimiert" />
        <meta property="og:url" content="https://erik-ivanov.de/konfigurator/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2023-05-16T20:57:53+00:00" />
        <meta property="og:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Erik Ivanov – Konfigurieren Sie Ihre Webseite" />
        <meta name="twitter:description" content="Digitalagentur für Webdesign, Webentwicklung und Online Marketing. ✓ zuverlässig, günstig, Zielgruppen optimiert" />
        <meta name="twitter:image" content="https://erik-ivanov.de/wp-content/uploads/2020/01/erik-ivanov-logo.jpg" />
      </Helmet>
      <div>
        <Konfigurator/>
        <Footer/>
      </div>
    </>
  )
}

function Home() {
  return (
    <>
      <Helmet>
        <title>Erik Ivanov – Webdesign aus Schwerin</title>
        <meta name="description" content="Digitalagentur für Webdesign, Webentwicklung und Online Marketing. ✓ zuverlässig, günstig, Zielgruppen optimiert" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Erik Ivanov – Professioneller Webdesigner aus Schwerin" />
        <meta property="og:description" content="Digitalagentur für Webdesign, Webentwicklung und Online Marketing. ✓ zuverlässig, günstig, Zielgruppen optimiert" />
        <meta property="og:url" content="https://erik-ivanov.de/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2023-05-16T20:57:53+00:00" />
        <meta property="og:image" content={socialCard} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Erik Ivanov – Professioneller Webdesigner aus Schwerin" />
        <meta name="twitter:description" content="Digitalagentur für Webdesign, Webentwicklung und Online Marketing. ✓ zuverlässig, günstig, Zielgruppen optimiert" />
        <meta name="twitter:image" content={socialCard} />
      </Helmet>
      <Intro ratingStars={true} googlePartner={false} title="Professioneller<br/><span>Webdesigner</span><br/>aus Schwerin"/>
      <div>
        <Welcome/>
        <UeberMich/>
        <Stats/>
        <Projects/>
        <Clients/>
        <Footer/>
      </div>
    </>
  );
}

function About() {
  return (
    <>
      <Helmet>
        <title>Über Mich | Erik Ivanov</title>
        <meta name="description" content="Digitalagentur für Webdesign, Webentwicklung und Online Marketing. ✓ zuverlässig, günstig, Zielgruppen optimiert" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://erik-ivanov.de/ueber-mich/" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Über Mich | Erik Ivanov" />
        <meta property="og:description" content="Digitalagentur für Webdesign, Webentwicklung und Online Marketing. ✓ zuverlässig, günstig, Zielgruppen optimiert" />
        <meta property="og:url" content="https://erik-ivanov.de/ueber-mich/" />
        <meta property="og:site_name" content="Erik Ivanov" />
        <meta property="article:modified_time" content="2023-05-16T20:57:53+00:00" />
        <meta property="og:image" content={socialCard} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Über Mich | Erik Ivanov" />
        <meta name="twitter:description" content="Digitalagentur für Webdesign, Webentwicklung und Online Marketing. ✓ zuverlässig, günstig, Zielgruppen optimiert" />
        <meta name="twitter:image" content={socialCard} />
      </Helmet>
      <Intro ratingStars={true} googlePartner={false} title="Mehr über<br/><span>Erik Ivanov</span>"/>
      <div>
        <Stats2/>
        <Clients/>
        <Footer/>
      </div>
    </>
  );
}

export default App;
