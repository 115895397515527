import React, { Component } from 'react';
import { connect } from "react-redux"
import { Link, Navigate } from "react-router-dom";
import { updateConfig } from '../redux/actions/configurator.actions'
import cn from 'classnames';
import styles from '../scss/configurator.module.scss';
import validator from 'validator';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Textarea from 'react-validation/build/textarea';
import Button from 'react-validation/build/button';
import axios from 'axios';

const API_PATH = 'https://erik-ivanov.de/api.php';

const required = (value) => {
    if (!value.toString().trim().length) {
        return 'Pflichtfeld';
    }
  };
   
const email = (value) => {
    if (!validator.isEmail(value)) {
        return `${value} ist keine gültige E-Mail Adresse.`
    }
};

const getInstallationMonth = () => {
    var TodayDate = new Date();
    const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni",
        "Juli", "August", "September", "Oktober", "November", "Dezember"
    ];
    var newDate = new Date(TodayDate.setMonth(TodayDate.getMonth() + 1));
    var m = newDate.getMonth();
    return monthNames[m];
}

const getInstallationYear = () => {
    var TodayDate = new Date();
    var newDate = new Date(TodayDate.setMonth(TodayDate.getMonth() + 1));
    var y = newDate.getFullYear();
    return String(y);
}

class KonfiguratorAuswertung extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            clientname: '',
            clientemail: '',
            clientphone: '',
            configurator: this.props.configurator,
            redirect: false
        }
    }
    componentDidMount() {
        setTimeout(() => this.setState({ loading: false }), 1000);
    }
    handleSubmit = ( event ) => {
        event.preventDefault();
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: {
                username: this.state.clientname,
                useremail: this.state.clientemail,
                userphone: this.state.clientphone,
                confwebsite: this.state.configurator.hasWebsite,
                confwebsiteurl: this.state.configurator.websiteURL,
                companyName: this.state.configurator.companyName,
                conflogo: this.state.configurator.hasLogo,
                confbrancheadvisory: this.state.configurator.branch.advisory,
                confbrancheservice: this.state.configurator.branch.service,
                confbranchegastronomy: this.state.configurator.branch.gastronomy,
                confbranchehealth: this.state.configurator.branch.health,
                confbranchetrade: this.state.configurator.branch.trade,
                confbranchecraft: this.state.configurator.branch.craft,
                confbrancheindustry: this.state.configurator.branch.industry,
                confbrancheother: this.state.configurator.branch.other,
                confpagesonethree: this.state.configurator.sites.onethree,
                confpagesfoursix: this.state.configurator.sites.foursix,
                confpagessevennine: this.state.configurator.sites.sevennine,
                confpagesmorenine: this.state.configurator.sites.morenine,
                confgoogle: this.state.configurator.googleBusiness,
                confPrice: this.calcuateWebsite(),
                type: 'configurator'
            }
        })
        .then(result => {
            this.setState({ redirect: true });
        })
        .catch(error => this.setState({ error: error.message }));
    }
    calcuateWebsite( type ) {
        let price = this.props.configurator.basePrice;
        let brancheSurcharge = this.props.configurator.brancheSurcharge;
        let sitesSurcharge = this.props.configurator.sitesSurcharge;
        let googleSurcharge = this.props.configurator.googleSurcharge;
        price = brancheSurcharge + price + googleSurcharge;
        if( type == 'support' ) {
            return String( new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format( ( brancheSurcharge + sitesSurcharge + price ) / 2 ) ) + '\xa0-\xa0' + String( new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format( ( brancheSurcharge + price + 850 ) / 2 ) );
        } else {
            return String( new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format( brancheSurcharge + sitesSurcharge + price + 425) );
        }
    }
    render() { 
        return (
            <>
                {(this.state.loading) ?
                    <section className={cn(styles.confLoading)}>
                        <div className={cn(styles.ldsRing)}>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </section>
                :
                <>
                <div class={cn(styles.confContainer)}>
                    <div className="container">
                        <div className={cn(styles.confHeadline)}>
                            <h1>Nur noch ein Schritt zu<br/><span>Ihrer neuen Webseite.</span></h1>
                        </div>
                        <section className={cn(styles.confWrapper)}>
                            <div className={cn(styles.confResult)}>
                                <div className={cn(styles.confResultSummary)}>
                                    <h3 className="aligncenter">Ihre neue Webseite</h3>
                                    <ul className={cn(styles.confList)}>
                                        <li><span>{(this.state.configurator.hasWebsite) ? 'Ja' : 'Nein'}</span><div className={cn(styles.confListTitle)}>Haben Sie bereits eine Webseite?</div></li>
                                        <li><span>{(this.state.configurator.hasLogo) ? 'Ja' : 'Nein'}</span><div className={cn(styles.confListTitle)}>Haben Sie bereits ein Logo?</div></li>
                                        {(this.state.configurator.selectedBranch != null) ?
                                            <li><span>{this.state.configurator.selectedBranch}</span><div className={cn(styles.confListTitle)}>In welcher Branche sind Sie tätig?</div></li>
                                        :
                                            null
                                        }
                                        <li><span>{(this.state.configurator.sites.onethree) ? '1-3 Seiten' : ''}{(this.state.configurator.sites.foursix) ? '4-6 Seiten' : ''}{(this.state.configurator.sites.sevennine) ? '7-9 Seiten' : ''}{(this.state.configurator.sites.morenine) ? 'mehr als 9 Seiten' : ''}</span><div className={cn(styles.confListTitle)}>Wie viele Unterseiten benötigen Sie etwa?</div></li>
                                        <li><span>{(this.state.configurator.googleBusiness) ? 'Ja' : 'Nein'}</span><div className={cn(styles.confListTitle)}>Möchten Sie ein Google My Business Profil?</div></li>
                                    </ul>
                                    <div className={cn(styles.confPrice)}>
                                        {this.calcuateWebsite()}
                                        <span className={cn(styles.confPriceNotice)}>voraussichtliche Kosten<br/><small>zzgl. MwSt</small></span>
                                    </div>
                                    <p className="aligncenter">
                                        <Link to="/konfigurator/" className="btn link" title="Konfiguration bearbeiten">
                                            Konfiguration bearbeiten
                                        </Link>
                                        <a href onClick={() => document.getElementById("contactForm").scrollIntoView({ behavior: "smooth", block: 'center' })} className="btn gold" title="Angebot anfordern">
                                            Angebot anfordern
                                        </a>
                                    </p>
                                    <p className="aligncenter">
                                        <small>Projektbeginn ab: {getInstallationMonth()} {getInstallationYear()}</small>
                                    </p>
                                </div>
                            </div>
                            <div className={cn(styles.confInnerCenter)} id="contactForm">
                                <div className={cn(styles.confForm)}>
                                    <div className={cn(styles.confFormInner)}>
                                        <h3>Neue Webseite anfragen</h3>
                                        <div className={cn(styles.confFormElement)}>
                                            <Form onSubmit={this.handleSubmit.bind(this)}>
                                                <div className={cn(styles.confFormInput)}>
                                                    <div className={cn(styles.confInput)}>
                                                        <Input type="text" name="customerFirstname" placeholder="Vorname*" onChange={e => this.setState({ clientname: e.target.value })} value={this.state.clientname} validations={[required]}/>
                                                    </div>
                                                    <div className={cn(styles.confInput)}>
                                                        <Input type="text" name="customerLastname" placeholder="Nachname*" onChange={e => this.setState({ clientname: e.target.value })} value={this.state.clientname} validations={[required]}/>
                                                    </div>
                                                    <div className={cn(styles.confInput)}>
                                                        <Input type="tel" name="customerTel" placeholder="Telefonnummer*" onChange={e => this.setState({ clientphone: e.target.value })} value={this.state.clientphone} validations={[required]}/>
                                                    </div>
                                                    <div className={cn(styles.confInput)}>
                                                        <Input type="email" name="customerMail" placeholder="E-Mail*" onChange={e => this.setState({ clientemail: e.target.value })} value={this.state.clientemail} validations={[required, email]}/>
                                                    </div>
                                                </div>
                                                <p>
                                                    <small>
                                                    Mit dem Absenden des Formulars erklären Sie sich damit einverstanden, dass Ihre Daten zur Bearbeitung Ihres Anliegens verwendet werden. Weitere Informationen und Widerrufshinweise finden Sie in unserer <Link to="/datenschutzerklaerung/">Datenschutzerklärung</Link>.
                                                    </small>
                                                </p>
                                                <button type="submit" className="btn gold block" title="Angebot anfordern">Angebot anfordern</button>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                                <div className={cn(styles.confAdvice)}>
                                    <h3>Sie brauchen Beratung?</h3>
                                    <p>Gerne beraten wir Sie vorab telefonisch oder per E-Mail.</p>
                                    <p>
                                        <a href="mailto:hallo@erik-ivanov.de" title="Nachricht schreiben">hallo@erik-ivanov.de</a><br/>
                                        <a href="tel:+4938548597862" title="Jetzt anrufen">0385 48597862</a>
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>
                    { this.state.redirect && <Navigate to="/vielen-dank"/> }
                </div>
                </>
                }
            </>
        );
    }
}

const mapStateToProps = (state)=>{
    return{
        configurator: state.configurator.configurator
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        updateConfig: (state)=>{dispatch(updateConfig(state))}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KonfiguratorAuswertung)